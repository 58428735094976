import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import styled from "@emotion/styled";
import { Spacer, MediumText, SmallText, FlexRow, ChangeList, ChangeListItem } from "../Utility";
import { Wrapper } from "./GridStyles";
import { Input } from "@vds/inputs";
import { Tooltip } from "@vds/tooltips";
import _ from "lodash";
import { ColumnHeader } from "./ColumnHeader";
import { Calendar } from "primereact/calendar";
import csvIcon from "../../../assets/icons/export-csv.png";
import pdfIcon from "../../../assets/icons/export-pdf.png";
import xlsIcon from "../../../assets/icons/export-xls.png";
import searchIcon from "../../../assets/icons/search_blk.png";
import { FilterMatchMode, FilterOperator, FilterService } from "primereact/api";
import moment from "moment";
import { Parser } from "json2csv";
import { ReactComponent as ResetButtonIcon } from "../../../assets/icons/reset.svg";

const formattedData = (data) => {
  return data == null ? "--" : data;
};

const formatUser = (data) => {
  return data == null ? "--" : data.replace(/@verizonconnect.com|@verizon.com/g, "");
};

const formattedWrapData = (data) => {
  return data == null
    ? "--"
    : data.map((item, index) => {
        return (
          <ChangeListItem key={index} primitive="span">
            {item}
          </ChangeListItem>
        );
      });
};

const formatDate = (value, dateFormat, timezone = "") => {
  if (!value) {
    return formattedData(value);
  }
  if (dateFormat) {
    return `${moment(value).format(dateFormat)} ${timezone}`.trim();
  }
  return typeof value.toLocaleDateString === "function"
    ? value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      })
    : value;
};

FilterService.register("customGlobalFilter", (value, filter) => {
  if (filter === undefined || filter === null || filter.trim() === "") {
    return true;
  }

  if (value === undefined || value === null) {
    return false;
  }
  if (value instanceof Date) {
    return formatDate(value).trim().indexOf(filter.trim()) !== -1;
  }
  return value.toString().toLowerCase().indexOf(filter.toString().toLowerCase()) !== -1;
});

const rowsDefault = [{ row: "" }];
const colsDefault = [{ cols: "" }];

const InlineSpan = styled.span`
  display: inline-flex;
  align-items: center;
`;

const InlineInput = styled(Input)`
  display: inline-flex !important;
`;

const StyledDropdown = styled(Dropdown)`
  width: 25px;
`;

const IconWrapper = styled.div`
  /* :hover{
background: ${({ theme }) => theme.background};
} */
  :focus {
    border: 1px solid black !important;
    outline: none;
    box-shadow: none;
  }
  height: 24px;
  width: 24px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
`;

const StyledCheckbox = styled(Input)`
  font-weight: 100;
  font-size: 14px !important;
  /* align-self: center; */
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px 0 15px;
  height: 30px;
  border-bottom: 1px solid black;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 75%;
`;

const CustomTooltip = styled(Tooltip)`
  > span > button > span:nth-child(2) > span {
    width: 24px;
    height: 24px;
    left: 8px;
  }
  > span > button > span:nth-child(2) > svg {
    display: none;
  }
  > span > button > span:nth-child(3) > span {
    width: max-content;
    left: 8px;
    > span:nth-child(2) {
      width: 24px;
      height: 24px;
      left: 12px;
    }
  }
`;

export const GridComponent = React.forwardRef(
  (
    {
      title,
      /** method for hyperlink click */
      hyperLinkClicked,
      /** message in case of no records*/
      noDataMessage = "No data to display",
      /** true if checkbox is needed*/
      isSelectionEnabled = false,
      /** method to get the selected checkbox data*/
      getSelectedItems,
      /** data */
      rows = { rowsDefault },
      /**field mapping, is a list of objects in the from
  {
    key: "Header name",
    value: "field name in data",
  },*/
      flyOutMenu,
      columns = { colsDefault },
      isExportEnabled = false,
      showDownloadAllOption = false,
      hideGlobalFilter,
      isHyperLink,
      rowExpansionTemplate,
      rowExpansionPosition,
      threeDotsTemplate,
      paginatorAriaLabel,
      renderDataWithImage,
      whiteSpace,
      paginator,
      enableGlobalSearch = true,
      customHeaderControls,
      selectionMode = "multiple",
      pdfHeight = "900",
      expandedRow,
      rowClassName,
      stateStorage,
      customSaveState,
      customRestoreState,
      enableColumnSelection,
      reorderableColumns,
      showResetButton = false,
      itemsPerPageDropDownValues = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
      ],
      showActionIcon,
      scrollHeight = "400px",
      scrollable = true,
      scrollDirection = "horizontal",
      isRadioSelectEnabled = false,
      /**columnsForDownloads: Columns to be used in excel and pdf downloads*/
      columnsForDownloads = [],
      /**rowsForDownloads: Rows to be used in excel and pdf downloads*/
      rowsForDownloads = [],
      fetchAllRecords,
      isDownloadLoader,
      handlePageChange = () => {},
      totalRecords,
      isRemote = false,
    },
    ref
  ) => {
    const [selectedItem, setSelectedItem] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [rowsInAPage, setRowsInAPage] = useState(itemsPerPageDropDownValues[0].value);
    const prefsLoaded = useRef(false);
    const menuRight = useRef(null);
    const toast = useRef(null);
    const [exportCheckboxSelected, setExportCheckboxSelected] = useState(false);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [expandedRows, setExpandedRows] = useState([]);
    const [paginatorError, setPaginatorError] = useState(false);
    const [paginatorErrorText, setPaginatorErrorText] = useState("Press 'Enter' key to go to this page.");
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [selectedColumnsDropdown, setSelectedColumnsDropdown] = useState(() => columns.map((item) => item.value));

    useEffect(() => {
      // setSelectedColumns(columns);
      // setSelectedColumnsDropdown(selectedColumns.map((item) => item.value));
      initFilters();
    }, []);

    const exportColumns = columns.map((col) => ({ title: col.key, dataKey: col.value, isDate: col.isDate, dateFormat: col.dateFormat }));

    const onPageInputChange = (event) => {
      setCurrentPage(event.target.value);
    };

    // TODO: Find a way to limit the number of times this method is being called
    const onCustomSaveState = (state) => {
      if (prefsLoaded) {
        state.selectedColumns = selectedColumns.map((item) => item.value);
        customSaveState(state);
      }
    };

    const onCustomRestoreState = async (state) => {
      const response = await customRestoreState(state);
      if (response?.selectedColumns) {
        const mappedItems = response.selectedColumns.map((item) => columns.find((columnItem) => item === columnItem.value));
        setSelectedColumns(mappedItems);
        setSelectedColumnsDropdown(response.selectedColumns);
      }
      ref.current.restoreTableState(response);
      prefsLoaded.current = true;
    };

    // useEffect(() => {
    //   var updatedSelectedItems = selectedItem.filter((item) => rows.includes(item));
    //   setSelectedItem(updatedSelectedItems);
    // }, [rows.length]);

    useEffect(() => {
      setExpandedRows(expandedRow);
    }, [rows]);

    const onCustomPage = (event) => {
      handlePageChange(event);
      setFirst(event.first);
      setRowsInAPage(event.rows);
      setCurrentPage(event.page + 1);
      setPaginatorError(false);
      setPaginatorErrorText("Press 'Enter' key to go to this page.");
    };

    const onPageInputKeyDown = (event, options) => {
      if (event.key === "Enter") {
        const page = parseInt(currentPage);
        if (page < 1 || page > options.totalPages) {
          setPaginatorError(true);
          setPaginatorErrorText(`Value must be between 1 and ${options.totalPages}.`);
        } else {
          const firstPage = currentPage ? options.rows * (page - 1) : 0;
          setFirst(firstPage);
          setPaginatorError(false);
          setPaginatorErrorText("Press 'Enter' key to go to this page.");
        }
      }
    };

    const onSelect = (e) => {
      setSelectedItem(e.value);
      getSelectedItems(e.value);
    };

    const pageTemplate = {
      layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      RowsPerPageDropdown: (options) => {
        return (
          <React.Fragment>
            <MediumText primitive="span">Items per page: </MediumText>
            <StyledDropdown
              value={options.value}
              options={itemsPerPageDropDownValues}
              onChange={options.onChange}
              ariaLabel={paginatorAriaLabel?.itemsPerPageLabel}
            />
          </React.Fragment>
        );
      },
      CurrentPageReport: (options) => {
        return (
          <FlexRow
            flexGrow="1"
            css={{
              "@media only screen and (max-width: 950px)": {
                flexBasis: "100%",
              },
            }}
          >
            <InlineSpan>
              <Spacer right="15px">
                <MediumText css={{ display: "inline-block" }} primitive="span">
                  Go to:
                </MediumText>
                <InlineInput
                  width="25px"
                  error={paginatorError}
                  value={currentPage}
                  onKeyDown={(e) => onPageInputKeyDown(e, options)}
                  onChange={onPageInputChange}
                  aria-label={paginatorAriaLabel?.goToLabel}
                />
                {paginatorError && <Tooltip title={paginatorErrorText} size="medium" surface="light" />}
              </Spacer>
              <Spacer right="15px">
                <MediumText css={{ display: "inline-block" }} primitive="span">
                  Displaying items {options.first} - {options.last} of {totalRecords ? totalRecords : options.totalRecords}
                </MediumText>
              </Spacer>
            </InlineSpan>
          </FlexRow>
        );
      },
      PageLinks: (options) => {
        return (
          <button {...options} role={paginatorAriaLabel?.pageNumberLabel && "button"} aria-label={paginatorAriaLabel?.pageNumberLabel} tabIndex="0">
            {options.element.props.children}
          </button>
        );
      },
    };

    const customFilterTemplate = (options) => {
      let filterType = columns.find((col) => col.value == options.field).filterType;
      const statuses = ["Active", "Inactive", "Pending"];
      const orderStatuses = ["Completed", "In progress", "Failed"];
      switch (filterType) {
        case "Status":
          return (
            <Dropdown
              value={options.value}
              options={statuses}
              onChange={(e) => options.filterCallback(e.value, options.index)}
              placeholder="Select a Status"
              className="p-column-filter"
              showClear
            />
          );
        case "Order Status":
          return (
            <Dropdown
              value={options.value}
              options={orderStatuses}
              onChange={(e) => options.filterCallback(e.value, options.index)}
              placeholder="Select a Status"
              className="p-column-filter"
              showClear
            />
          );
        case "Date":
          return (
            <Calendar
              value={options.value}
              onChange={(e) => options.filterCallback(e.value, options.index)}
              dateFormat="mm/dd/yy"
              placeholder="mm/dd/yyyy"
              mask="99/99/9999"
            />
          );
      }
    };

    const initFilters = () => {
      const obj = Object.fromEntries(
        columns.map((col) =>
          col.isDate
            ? [col.value, { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }]
            : [col.value, { operator: FilterOperator.AND, constraints: [{ value: null }] }]
        )
      );
      obj["global"] = { value: null, matchMode: "customGlobalFilter" };
      setFilters(obj);
      setGlobalFilterValue("");
    };

    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
      _filters["global"].value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
    };

    const downloadAllRecords = (downloadType, title) => {
      fetchAllRecords(title, downloadType);
    };

    const itemsVtus = [
      {
        label: "Download as",
        items: [
          {
            label: "CSV",
            icon: "pi pi-download",
            command: () => downloadAllRecords("CSV", title),
          },
          {
            label: "PDF",
            icon: "pi pi-file-pdf",
            command: () => downloadAllRecords("PDF", title),
          },
          {
            label: "Excel",
            icon: "pi pi-file-excel",
            command: () => downloadAllRecords("EXCEL", title),
          },
        ],
      },
    ];

    const filterIsDate = (list = []) => list.filter((item) => item.isDate);

    const formatData = (dataToExport) => {
      /** dataColumns: Check if columns for download is available, then filter */
      let dataColumns = columnsForDownloads ? filterIsDate(columnsForDownloads) : filterIsDate(columns);
      return dataToExport.map((item) => {
        dataColumns.forEach((columnItem) => {
          item[columnItem.value] = formatDate(item[columnItem.value], columnItem.dateFormat);
        });
        return item;
      });
    };

    const exportCSV = (title) => {
      const rowsToExport = Array.isArray(rowsForDownloads) && rowsForDownloads.length !== 0 ? rowsForDownloads : _.cloneDeep(rows);
      var dataToExport = exportCheckboxSelected ? selectedItem : rowsToExport;
      dataToExport = formatData(dataToExport);
      // If the table is unit history
      if (title === "Unit History") {
        try {
          dataToExport = dataToExport.map((item) => ({
            ...item,
            ChangedFieldsList: item.ChangedFieldsList.map((field) => `${field.FieldName}: ${field.NewValue}`).join(","),
          }));
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
      const exportColumnsForCSV =
        Array.isArray(columnsForDownloads) && columnsForDownloads.length !== 0
          ? columnsForDownloads
          : columns.map((col) => ({ label: col.key, value: col.value }));
      const json2csvParser = new Parser({ fields: exportColumnsForCSV });
      const csv = json2csvParser.parse(dataToExport);
      saveFile(csv, `SLibraEU_${title}_${moment().format()}`, "text/csv", ".csv");
    };

    const exportExcel = (title) => {
      const rowsToExport = Array.isArray(rowsForDownloads) && rowsForDownloads.length !== 0 ? _.cloneDeep(rowsForDownloads) : _.cloneDeep(rows);
      let dataToExport = exportCheckboxSelected ? selectedItem : rowsToExport;
      dataToExport = formatData(dataToExport);
      // If the table is unit history
      if (title === "Unit History") {
        try {
          dataToExport = dataToExport.map((item) => ({
            ...item,
            ChangedFieldsList: item.ChangedFieldsList.map((field) => `${field.FieldName}: ${field.NewValue}`).join(","),
          }));
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
      if (dataToExport.length > 0) {
        import("xlsx").then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(getDataForExport(dataToExport));
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
          saveFile(excelBuffer, "data", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8", ".xlsx");
        });
      }
    };

    const getDataForExport = (data) => {
      const columnList = Array.isArray(columnsForDownloads) && columnsForDownloads.length !== 0 ? columnsForDownloads : columns;
      return data.map((item) => {
        return Object.fromEntries(
          columnList.map((col) => [
            col.key,
            typeof item[col.value] === "object" || Array.isArray(item[col.value]) ? getNonNullValues(item[col.value]) : item[col.value],
          ])
        );
      });
    };

    const getNonNullValues = (v) => (v === null ? "--" : JSON.stringify(v));

    const saveFile = (buffer, fileName, type, extension) => {
      import("file-saver").then((module) => {
        if (module && module.default) {
          const data = new Blob([buffer], { type });
          module.default.saveAs(data, fileName + extension);
        }
      });
    };

    const calculateWidth = (cols) => {
      let total = 0;
      cols.forEach((item) => {
        let width = item.width ? item.width.substring(0, item.width.length - 2) : undefined;
        total += parseInt(width) ?? 200;
      });
      return total * 0.5;
    };

    const exportPdf = (title) => {
      var dataToExport = exportCheckboxSelected ? selectedItem : _.cloneDeep(rows);
      dataToExport = formatData(dataToExport);
      // If the table is unit history
      if (title?.props?.children === "Unit History") {
        try {
          dataToExport = dataToExport.map((item) => ({
            ...item,
            ChangedFieldsList: item.ChangedFieldsList.map((field) => `${field.FieldName}: ${field.NewValue}`).join(","),
          }));
        } catch (err) {
          dataToExport = dataToExport.map((item) => convertJsonToTable(item));
        }
      } else if (title?.props?.children === "Audit Logs") {
        try {
          dataToExport = dataToExport.map((item) => ({
            ...item,
            changes: item.changes.map((change) => change.replace(/(.*?): (.*?) -> (.*?)$/, '$1: From "$2" to "$3"')).join("\n"),
          }));
        } catch (err) {
          dataToExport = dataToExport.map((item) => convertJsonToTable(item));
        }
      } else {
        dataToExport = dataToExport.map((item) => convertJsonToTable(item));
      }

      if (dataToExport.length > 0) {
        import("jspdf").then((jsPDF) => {
          import("jspdf-autotable").then(() => {
            let pdfWidth = calculateWidth(columns);
            const doc = new jsPDF.default({
              unit: "px",
              format: [pdfWidth, pdfHeight],
              orientation: "landscape",
            });
            const pageWidth = doc.internal.pageSize.width;
            const textWidth =
              doc.getStringUnitWidth(
                title?.props?.children === "Audit Logs" || title?.props?.children === "Unit History" ? title.props.children : title
              ) * doc.internal.getFontSize();
            const xOffSet = (pageWidth - textWidth) / 2;
            doc.setFontSize(16);
            doc.text(
              title?.props?.children === "Audit Logs" || title?.props?.children === "Unit History" ? title.props.children : title,
              xOffSet,
              15
            );
            doc.autoTable({
              columns: exportColumns,
              body: dataToExport,
            });
            doc.save(`SLibraEU_${title}_${moment().format("YYYY-MM-DD")}.pdf`);
          });
        });
      }
    };

    function convertJsonToTable(data) {
      const tableData = {};
      columns.forEach((col) => {
        if (col.value in data) {
          const value = data[col.value];
          if (value !== null && !Array.isArray(value) && typeof value === "object") {
            tableData[col.value] = value
              .map((obj) => {
                return Object.entries(obj)
                  .map(([key, val]) => `${key}: ${val}`)
                  .join(", ");
              })
              .join(",\n");
          } else if (Array.isArray(value)) {
            tableData[col.value] = value.map((element) => {
              return convertJsonToTable(element);
            });
          } else {
            tableData[col.value] = value;
          }
        }
      });
      return tableData;
    }

    const getBodyToDisplay = (rowData, data) => {
      let colAttributes = columns.find((col) => col.value == data.field);
      if (colAttributes.customBodyCallback) {
        return colAttributes.customBodyCallback(rowData, data);
      }
      if (colAttributes.isDynamicFont) {
        let fontColor = rowData?.state === "Installed" && rowData?.isConfirmed !== true ? "red" : "";
        return (
          <SmallText primitive="span" color={fontColor}>
            {formattedData(rowData[data.field])}
          </SmallText>
        );
      }
      if (colAttributes.isHyperLink) {
        if (colAttributes.isList) {
          return <SmallText primitive="span">{getListToDisplay(rowData, colAttributes, data)}</SmallText>;
        } else {
          if (isHyperLink && !isHyperLink(rowData)) {
            return <SmallText primitive="span">{formattedData(rowData[data.field])}</SmallText>;
          }
          return (
            <SmallText
              primitive="span"
              style={{ "text-decoration": "underline", cursor: "pointer" }}
              onClick={() => hyperLinkClicked(rowData, colAttributes.key)}
            >
              {renderData(rowData[data.field], colAttributes)}
            </SmallText>
          );
        }
      } else {
        return renderContentForNonHyperlink(rowData, colAttributes, data);
      }
    };

    function getListToDisplay(rowData, colAttributes, data) {
      return rowData[data.field].map((itemInList, index) => {
        if (colAttributes.isHyperLink) {
          return (
            <>
              <SmallText
                primitive="span"
                key={index}
                style={{ "text-decoration": "underline", cursor: "pointer" }}
                onClick={() => hyperLinkClicked(rowData, colAttributes.key)}
              >
                {formattedData(itemInList[colAttributes.itemToDisplayInList])}
              </SmallText>
              <br />
            </>
          );
        } else {
          return (
            <>
              <SmallText primitive="span" key={index}>
                {formattedData(typeof itemInList == "string" ? itemInList : itemInList[colAttributes.itemToDisplayInList])}
              </SmallText>
              <br />
            </>
          );
        }
      });
    }

    const renderWrapData = (data, colAttributes) => {
      return colAttributes.isWrap ? formattedWrapData(data) : formattedData(data);
    };

    const renderData = (data, colAttributes) => {
      if (colAttributes.constantValue) {
        return formattedData(colAttributes.constantValue);
      }
      return colAttributes.containsImage ? renderDataWithImage(formattedData(data)) : formattedData(data);
    };

    const renderContentForNonHyperlink = (rowData, colAttributes, data) => {
      if (colAttributes.isList) {
        return <SmallText primitive="span">{getListToDisplay(rowData, colAttributes, data)}</SmallText>;
      } else if (colAttributes.isDate) {
        return <SmallText primitive="span">{formatDate(rowData[data.field], colAttributes.dateFormat, rowData.timezone)}</SmallText>;
      } else if (colAttributes.isUser) {
        return <ChangeList primitive="span">{formatUser(rowData[data.field])}</ChangeList>;
      } else if (colAttributes.isWrap) {
        return <ChangeList>{renderWrapData(rowData[data.field], colAttributes)}</ChangeList>;
      } else {
        return <SmallText primitive="span">{renderData(rowData[data.field], colAttributes)}</SmallText>;
      }
    };

    const clearFilter = () => {
      initFilters();
    };

    const onColumnToggle = (event) => {
      let selectedColumns = event.value;
      let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol === col.value));
      setSelectedColumns(orderedSelectedColumns);
      setSelectedColumnsDropdown(orderedSelectedColumns.map((item) => item.value));
    };

    const resetGrid = () => {
      ref.current.reset();
      setSelectedColumns(columns);
      setSelectedColumnsDropdown(columns.map((item) => item.value));
      initFilters();
      setFirst(0);
      setRowsInAPage(itemsPerPageDropDownValues[0].value);
    };

    const renderHeader = () => {
      return (
        <>
          <FlexRow justifyContent="space-between">
            <FlexRow alignItems="center">
              <MediumText bold>{title}</MediumText>
              {enableColumnSelection && (
                <MultiSelect
                  value={selectedColumnsDropdown}
                  display="chip"
                  options={columns}
                  optionLabel="key"
                  onChange={onColumnToggle}
                  style={{ width: "20em", marginLeft: "20px" }}
                  placeholder="Please select some columns"
                />
              )}
            </FlexRow>
            <FlexRow alignItems="center">
              {customHeaderControls}
              {enableGlobalSearch && (
                <SearchInputWrapper>
                  <SearchInput value={globalFilterValue} type="text" onChange={onGlobalFilterChange} placeholder="Search"></SearchInput>
                  <img src={searchIcon} height="20px" width="20px"></img>
                </SearchInputWrapper>
              )}
              {!hideGlobalFilter && (
                <IconWrapper tabIndex={0} onClick={clearFilter}>
                  <CustomTooltip title="Clear Filters" size="large"></CustomTooltip>
                  <span className="p-button-icon p-c p-button-icon-left pi pi-filter-slash"></span>
                </IconWrapper>
              )}
              {showResetButton && (
                <IconWrapper tabIndex={1} onClick={resetGrid}>
                  {/* <span className="p-button-icon p-c p-button-icon-left pi pi-filter-slash"></span> */}
                  <CustomTooltip title="Reset" size="large"></CustomTooltip>
                  <ResetButtonIcon css={{ padding: "1px" }} />
                </IconWrapper>
              )}
              {isExportEnabled && (
                <>
                  <IconWrapper tabIndex={2} onClick={() => exportCSV(title)}>
                    <CustomTooltip title="CSV" size="large"></CustomTooltip>
                    <img src={csvIcon}></img>
                  </IconWrapper>
                  <IconWrapper tabIndex={3} onClick={() => exportExcel(title)}>
                    <CustomTooltip title="Excel" size="large"></CustomTooltip>
                    <img src={xlsIcon}></img>
                  </IconWrapper>
                  <IconWrapper tabIndex={4} onClick={() => exportPdf(title)}>
                    <CustomTooltip title="PDF" size="large"></CustomTooltip>
                    <img src={pdfIcon}></img>
                  </IconWrapper>
                </>
              )}
              {showDownloadAllOption && (
                <>
                  <FlexRow>
                    <Toast ref={toast}></Toast>
                    <Menu model={itemsVtus} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                    <IconWrapper tabIndex={0} onClick={(event) => menuRight.current.toggle(event)}>
                      <CustomTooltip title="Download All Records" size="large"></CustomTooltip>
                      {isDownloadLoader == true ? (
                        <span className="p-button-icon p-c p-button-icon-left pi pi-spin pi-spinner"></span>
                      ) : (
                        <span className="p-button-icon p-c p-button-icon-left pi pi-download"></span>
                      )}
                    </IconWrapper>
                  </FlexRow>
                </>
              )}
              {isSelectionEnabled && isExportEnabled && (
                <StyledCheckbox
                  selected={exportCheckboxSelected}
                  type="checkbox"
                  name="default"
                  width="auto"
                  onChange={() => {
                    setExportCheckboxSelected(!exportCheckboxSelected);
                  }}
                >
                  Export Selected items only
                </StyledCheckbox>
              )}
            </FlexRow>
          </FlexRow>
        </>
      );
    };

    const renderEmptyMessage = () => {
      return <SmallText>{noDataMessage}</SmallText>;
    };

    const getDataType = (filterType) => {
      if (filterType == "Date") {
        return "date";
      } else {
        return "text";
      }
    };

    const onRowExpand = (event) => {
      setExpandedRows([event.data]);
    };

    const onRowCollapse = (event) => {
      setExpandedRows([]);
    };
    return (
      <div>
        <Wrapper colSizes={{ mobile: 4, tablet: 12, desktop: 12 }} autoResizeHead={true} fontSize="14px" colGutter={true} whiteSpace={whiteSpace}>
          <DataTable
            header={renderHeader()}
            sortMode="multiple"
            removableSort
            ref={ref}
            resizableColumns
            columnResizeMode="expand"
            scrollable={scrollable}
            scrollHeight={scrollHeight}
            scrollDirection={scrollDirection}
            filterDisplay="menu"
            emptyMessage={renderEmptyMessage()}
            filters={filters}
            responsiveLayout="scroll"
            value={rows}
            selectionMode={isSelectionEnabled ? "checkbox" : isRadioSelectEnabled ? "radiobutton" : ""}
            selectionPageOnly={isSelectionEnabled}
            selection={selectedItem}
            onSelectionChange={(e) => onSelect(e)}
            paginator={paginator}
            paginatorPosition="bottom"
            first={first}
            rows={rowsInAPage}
            stripedRows
            totalRecords={totalRecords ? totalRecords : rows}
            onPage={onCustomPage}
            lazy={isRemote}
            paginatorTemplate={pageTemplate}
            pageLinkSize={3}
            expandedRows={expandedRows}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRowIcon="pi pi-chevron-up"
            collapsedRowIcon="pi pi-chevron-down"
            rowClassName={rowClassName}
            stateStorage={stateStorage}
            customSaveState={onCustomSaveState}
            customRestoreState={onCustomRestoreState}
            reorderableColumns={reorderableColumns}
          >
            {rowExpansionTemplate && rowExpansionPosition === "beginning" && (
              <Column expander style={{ width: "3.5em", height: "34px", flex: "inherit" }} />
            )}
            {isSelectionEnabled && (
              <Column
                className="columnCheckBox"
                columnKey="1"
                key={"checkbox"}
                selectionMode={selectionMode}
                style={{ width: "3.5em", height: "34px", flex: "inherit" }}
              ></Column>
            )}
            {isRadioSelectEnabled && (
              <Column
                columnKey="1"
                key={"radiobutton"}
                selectionMode={selectionMode}
                style={{ width: "3.5em", height: "34px", flex: "inherit" }}
              ></Column>
            )}
            {selectedColumns.map((item) => {
              return item.isCustomFilter ? (
                <Column
                  key={item.key}
                  field={item.value}
                  columnKey={item.key}
                  body={getBodyToDisplay}
                  header={<ColumnHeader col={item.key} />}
                  dataType={getDataType(item.filterType)}
                  style={{
                    minWidth: `${item.width || "200px"}`,
                    minHeight: "34px",
                    padding: "4px 0 4px 0!important",
                    maxWidth: `${item.maxWidth || "none"}`,
                  }}
                  sortable={item.isSortable}
                  filter={item.isFilterEnabled}
                  filterElement={customFilterTemplate}
                ></Column>
              ) : (
                <Column
                  key={item.key}
                  field={item.value}
                  columnKey={item.key}
                  body={getBodyToDisplay}
                  header={<ColumnHeader col={item.key} ariaLabel={item.ariaLabel} />}
                  style={{
                    minWidth: `${item.width || "200px"}`,
                    minHeight: "34px",
                    padding: "4px 0 4px 0!important",
                    maxWidth: `${item.maxWidth || "none"}`,
                  }}
                  sortable={item.isSortable}
                  filter={item.isFilterEnabled}
                ></Column>
              );
            })}
            {showActionIcon && (
              <Column
                header={<ColumnHeader col="Actions" />}
                body={showActionIcon}
                style={{
                  width: flyOutMenu ? "9.8%" : "50px",
                  overflowX: flyOutMenu && "hidden",
                  height: "auto",
                  flex: "inherit",
                  backgroundColor: flyOutMenu && "white",
                }}
              />
            )}
            {threeDotsTemplate && <Column body={threeDotsTemplate} style={{ width: "3.5em", height: "auto", flex: "inherit" }} />}
            {rowExpansionTemplate && rowExpansionPosition === "end" && (
              <Column expander style={{ width: "3.5em", height: "auto", flex: "inherit" }} />
            )}
          </DataTable>
        </Wrapper>
      </div>
    );
  }
);
GridComponent.displayName = "GridComponent";
