import React, { useState, useContext, useEffect, useRef } from "react";
import { Body } from "@vds/typography";
import { SLButton } from "../../components/core/Controls";
import { FlexRow, SmallText, Spacer } from "../../components/core/Utility/index";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { Loader } from "@vds/loaders";
import { handleERPError } from "../../utility/sessionData";
import { completeJob } from "../../services/scheduler/completeJob";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { css } from "@emotion/react";
import { InputNumber } from "primereact/inputnumber";
import { salesOrgBasedCurrency } from "../../constants/PartialCompletionReasons";
import { Table, TableHead, TableBody, TableRow, Cell, TableHeader } from "@vds/tables";
import styled from "styled-components";
import { DropdownSelect } from "@vds/selects";
import { Dialog } from "primereact/dialog";
import { Stepper, Step, StepTitle, useStepper } from "react-progress-stepper";
import InstallPartnerFees from "./JobActionStepperSteps/InstallPartnerFees";
import CustomerFeesComp from "./JobActionStepperSteps/CustomerFees";
import ConfirmationComp from "./JobActionStepperSteps/Confirmation";
import { max } from "lodash";
import { Notification } from "@vds/notifications";
import { generate } from "shortid";
import { TextLink } from "@vds/buttons";
import { switchTab } from "../C4S Decommission - Replacement/utils";

export const CustomisedInput = css`
  border: 0.0625rem solid #d8dada;
  width: 80%;
  height: 2rem;
  .p-inputtext {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin-top: 0px;
    padding: 0.5rem 0.5rem 2rem 0.5rem;
    position: relative;
    border: none;
    color: #000000;
    background-color: transparent;
  }
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin-top: 0px;
    position: relative;
    border: none;
    color: #000000;
    background-color: transparent;
  }
`;
export const CustomisedModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

export const CustomisedReasonDropdown = css`
  width: 25vw;
  @media screen and (max-width: 990px) {
    width: 38vw;
  }
  @media screen and (min-width: 1400px) {
    width: 20vw;
  }
  @media screen and (min-width: 1650px) {
    width: 16vw;
  }
  @media screen and (min-width: 2100px) {
    width: 14vw;
  }
`;

export const CustomDropdown = styled(DropdownSelect)`
  & div {
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
    & label {
      & p {
        font-size: 12px;
        color: ${(props) => (props.disabled ? "#d8dada" : "")};
        & span {
          &::after {
            color: #747676;
          }
        }
      }
    }
  }
  }
  & select {
    font-size: 12px;
    color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      // color: ${(props) => (props.disabled || props.disableValue ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
`;
const CustomTableHeader = css`
  // width: 95%;
  /* margin-left: 0.5rem; */
  margin-bottom: 0px;
  table-layout: fixed;
  thead {
    border-bottom: 2px solid #000000;
    margin-bottom: 3px;
    th {
      padding: 1rem 0rem 0.75rem 0rem;
      /* padding: 1rem 0.5rem 0.75rem 0.5rem; */
      div {
        p {
          font-size: 0.7rem;
        }
      }
    }
  }
  td {
    padding: 8px 2px 5px 0px;
    /* padding: 8px 2px 5px 15px; */
  }
`;

export const CustomisedNotification = css`
  & span:last-child {
    max-width: fit-content;
  }
`;

const CompleteJob = ({ jobDetails, setComponent, notify, descriptionList }) => {
  updateNewrelicStats();
  const { source, caseDetails, accountDetails, cspDetails, installPartnerFees, customerFees, totalCustomerFees, OneERPStatus, OneERPSubStatus } =
    useContext(AccountDetailsContext);
  const [isLoading, setLoader] = useState(false);
  const [installedVehicles, setInstalledVehicles] = useState(jobDetails.vehicles);
  const [kbArticleLink] = useState("https://vzt-kb.vtitel.com/Reveal/Reveal_Support/Reveal_Scheduling/Customer_No_Show_Process");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [formStep, setFormStep] = useState(0);
  const [notification, setNotification] = useState();
  const [systemUserNotification, setSystemUserNotification] = useState();
  const [installPartnerFeesData, setInstallPartnerFeesData] = useState([]);
  const [customerFeesData, setCustomerFeesData] = useState([]);
  const [mmidData, setMmidData] = useState([]);
  const [selectedERPStatus, setSelectedERPStatus] = useState({ value: "", error: false, errorText: "Select 1ERP Status", success: false });
  const [selectedERPSubStatus, setSelectedERPSubStatus] = useState({ value: "", error: false, errorText: "Select 1ERP Sub Status", success: false });
  const [stepsEnable, setStepsEnable] = useState({ first: true, second: true, third: true });
  const { step, incrementStep, decrementStep } = useStepper(0, 4);
  const [newJobDetails, setNewJobDetails] = useState([]);

  const kbNoAdditionalServices = "https://vzt-kb.vtitel.com/Customer_Onboarding/Reveal_Scheduling/Using_the_Scheduling_Portal";

  //for accessibility
  const dialogFocusRef = useRef();

  let emptyMaterialLineId = [],
    emptyMaterialId = [],
    emptyItemDescription = [],
    emptyQuantity = [],
    emptyProcessingCode = [],
    emptyLineItemUniqueId = [];

  const completeJobs = async () => {
    if (jobDetails?.contactName != null) {
      let name, fName, lName;
      name = jobDetails?.contactName?.split(" ");
      fName = name ? (name.length > 1 ? name.slice(0, name.length - 1).join(" ") : name[0]) : "";
      lName = name?.length > 1 ? name[name.length - 1] : "";
      setFirstName(fName);
      setLastName(lName);
    }
    let tempJobDetails;
    tempJobDetails = jobDetails?.jobDurationDetails && jobDetails?.jobDurationDetails?.length > 0 ? jobDetails?.jobDurationDetails : [];
    mmidData &&
      mmidData?.map((newData) => {
        tempJobDetails?.map((existingJob) => {
          if (existingJob.materialId == newData.materialId) {
            existingJob.customerPONumber = source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null;
            existingJob.quantity = newData.quantity ? newData.quantity : 0;
            existingJob.manualAdjustments = newData.manualAdjustQty ? newData.manualAdjustQty : 0;
            existingJob.rhiInstalledQuantity = newData.rhiInstalledQty ? newData.rhiInstalledQty : 0;
          }
        });
      });
    installPartnerFees.installPartnerFees &&
      installPartnerFees.installPartnerFees.map((newData) => {
        tempJobDetails =
          tempJobDetails?.length > 0
            ? tempJobDetails
            : jobDetails?.jobDurationDetails && jobDetails?.jobDurationDetails?.length > 0
            ? jobDetails?.jobDurationDetails
            : [];
        let maxLineId = max(
          tempJobDetails?.map((job) =>
            (job.materialLineID ? job.materialLineID : job.materialLineId) ? Number(job.materialLineID ? job.materialLineID : job.materialLineId) : 0
          )
        );
        let materialLineId = maxLineId ? maxLineId : 0;
        let materialUniqueId = null; //newData.materialLineId;
        //  if (newData && newData?.quantity?.value && !materialUniqueId) {
        materialLineId += 10;
        materialUniqueId = materialLineId.toString().padStart(6, "0");
        // }
        let tempObj = {};
        tempObj.id = null;
        tempObj.materialLineID = materialUniqueId;
        tempObj.materialId = newData?.mmid?.value;
        tempObj.customerPONumber = source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null;
        tempObj.materialName = newData?.itemDescription?.value;
        let processCode = newData?.processingCode?.value;
        tempObj.processingCode = processCode?.split(" ")[0];
        tempObj.quantity = newData?.quantity?.value;
        tempObj.unitOfMeasure = newData?.unit?.value;
        tempObj.lineItemUniqueId = jobDetails.jobId + "_" + materialUniqueId;
        if (tempObj.materialId != "") tempJobDetails.push(tempObj);
      });
    customerFees.customerFees &&
      customerFees.customerFees.map((newData) => {
        tempJobDetails =
          tempJobDetails?.length > 0
            ? tempJobDetails
            : jobDetails?.jobDurationDetails && jobDetails?.jobDurationDetails?.length > 0
            ? jobDetails?.jobDurationDetails
            : [];
        let maxLineId = max(
          tempJobDetails?.map((job) =>
            (job.materialLineID ? job.materialLineID : job.materialLineId) ? Number(job.materialLineID ? job.materialLineID : job.materialLineId) : 0
          )
        );
        let materialLineId = maxLineId ? maxLineId : 0;
        let materialUniqueId = null; //newData?.customerMmid?.value;
        // if (newData && newData?.customerQuantity?.value && !materialUniqueId) {
        materialLineId += 10;
        materialUniqueId = materialLineId.toString().padStart(6, "0");
        //  }
        let tempObj = {};
        tempObj.id = null;
        tempObj.customerPONumber = source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null;
        tempObj.materialName = newData.customerItemDescription.value;
        tempObj.materialId = newData?.customerMmid?.value;
        tempObj.processingCode = "ZBTP";
        tempObj.quantity = newData?.customerQuantity?.value;
        tempObj.customerCharge = newData?.charge?.valueInt;
        tempObj.lineItemUniqueId = jobDetails.jobId + "_" + materialUniqueId;
        tempObj.materialLineID = materialUniqueId;
        tempObj.unitOfMeasure = newData?.unit?.value;
        if (tempObj.materialId != "") tempJobDetails.push(tempObj);
      });
    let cspCaseDetails = {
      assignedTo: null,
      assignedToEmail: null,
      assignedToEid: null,
    };
    if (source.source?.toLowerCase() === "case") {
      let caseData = caseDetails.caseDetails.length ? caseDetails.caseDetails.find((item) => item.DefaultCase === "1") : {};
      cspCaseDetails = {
        assignedTo: caseData?.CaseOwner || null,
        assignedToEmail: caseData?.CaseOwnerEmail || null,
        assignedToEid: caseData?.CaseOwnerEId || null,
      };
    } else {
      let cspData = cspDetails.cspDetails.length ? cspDetails.cspDetails.find((item) => item.DefaultCSP === "1") : {};
      cspCaseDetails = {
        assignedTo: cspData?.CSPOwner || null,
        assignedToEmail: cspData?.CSPOwnerEmail || null,
        assignedToEid: cspData?.CSPOwnerEId || null,
      };
    }
    let payload = {
      installCompletedQuantity: installedVehicles ? installedVehicles : "",
      serviceJobId: jobDetails?.serviceJobId ? jobDetails.serviceJobId : "",
      partialCompletionReason: "",
      serviceAddress: {
        lastName: lastName ? lastName : "",
        firstName: firstName ? firstName : "",
        address1: jobDetails?.installationAddress?.addressOne ? jobDetails.installationAddress.addressOne : "",
        address2: jobDetails?.installationAddress?.addressTwo ? jobDetails.installationAddress.addressTwo : "",
        city: jobDetails?.installationAddress?.city ? jobDetails.installationAddress.city : "",
        state: jobDetails?.installationAddress?.state ? jobDetails.installationAddress.state : "",
        postalCode: jobDetails?.installationAddress?.zip ? jobDetails.installationAddress.zip : "",
        country: jobDetails?.installationAddress?.country ? jobDetails.installationAddress.country : "",
      },
      documentType: source.source === "csp" ? "ZINS" : "ZSRQ",
      salesOrg: cspDetails?.cspDetails[0]?.Opp_Sales_Org ? cspDetails.cspDetails[0].Opp_Sales_Org : jobDetails?.salesOrg,
      currencyCode: accountDetails?.currencyCode ?? "",
      distributionChannel: jobDetails?.distributionChannel ? jobDetails.distributionChannel : "",
      division: jobDetails?.division ? jobDetails.division : "",
      crmReferenceId: source.source === "csp" ? cspDetails?.cspDetails[0]?.CRM_Ref_ID : caseDetails?.caseDetails[0]?.CaseNumber,
      contractAccount: jobDetails?.contractAccountId,
      erpVendorId: jobDetails?.installerId ? jobDetails.installerId : "",
      //salesRepId: accountDetails.employeeId,
      //assignedTo: accountDetails?.employeeId ? accountDetails.employeeId : "",
      vendorMessage: jobDetails?.vendorMessages ? jobDetails.vendorMessages : "", // from job details
      subStatus: selectedERPSubStatus?.value ? selectedERPSubStatus.value : "",
      oldDeliveryNo: "",
      erpStatus: selectedERPStatus?.value ? selectedERPStatus.value : "",
      erpSubStatus: selectedERPSubStatus?.value ? selectedERPSubStatus.value : "",
      soldToParty: accountDetails?.erpBpNumber ? accountDetails.erpBpNumber : "", //from csp (1ERP BP number)
      shipToParty: accountDetails?.erpBpNumber ? accountDetails.erpBpNumber : "", //from csp (1ERP BP number)
      modifiedBy: accountDetails?.employeeFullName,
      modifiedByEmail: accountDetails?.employeeEmail,
      modifiedByEid: accountDetails?.employeeId,
      assignedTo: cspCaseDetails?.assignedTo,
      assignedToEmail: cspCaseDetails?.assignedToEmail,
      assignedToEid: cspCaseDetails?.assignedToEid,
      partners: [
        {
          party: accountDetails?.resellerBusinessPartnerNumber || accountDetails?.erpBpNumber || "",
          partRoleCode: "AG",
        },
        {
          party: accountDetails?.erpBpNumber ?? "",
          partRoleCode: "WE",
        },
        {
          party: accountDetails?.resellerBusinessPartnerNumber || accountDetails?.erpBpNumber || "",
          partRoleCode: "Z6",
          accountName: accountDetails?.customerName ? accountDetails.customerName : "",
          contactName: jobDetails?.contactName ? jobDetails.contactName : "",
          contactPhone: jobDetails?.contactPhone ? jobDetails.contactPhone : "",
          contactEmail: jobDetails?.contactEmail ? jobDetails.contactEmail : "",
          street: jobDetails?.installationAddress?.addressOne ? jobDetails?.installationAddress?.addressOne : "",
          city: jobDetails?.installationAddress?.city ? jobDetails?.installationAddress?.city : "",
          state: jobDetails?.installationAddress?.state ? jobDetails?.installationAddress?.state : "",
          country: jobDetails?.installationAddress?.country ? jobDetails?.installationAddress?.country : "",
          zip: jobDetails?.installationAddress?.zip ? jobDetails?.installationAddress?.zip : "",
          telephone: "",
        },
      ],
      itemDetails: tempJobDetails?.map((job) => {
        return {
          id: job.jobDurationDetailId ? job.jobDurationDetailId : job.id,
          materialLineId: job?.materialLineID ? job?.materialLineID : job?.materialLineId ? job?.materialLineId : "",
          materialId: job?.materialId ? job.materialId : "",
          itemDescription: job?.materialName ? job.materialName : "",
          quantity: job?.quantity ?? "",
          unitMeasure: job?.unitOfMeasure ? job.unitOfMeasure : "",
          techRefId: job?.techRefId ? job.techRefId : "",
          processingCode: job?.processingCode ? job.processingCode : "",
          lineItemUniqueId: job?.lineItemUniqueId ? job.lineItemUniqueId : "",
          customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CSP ONLY
          customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CASE ONLY
          baseItemId: "", //empty
          esnToBeReturned: "",
          installedQuantity: job?.quantity ? job.quantity : 0,
          manualAdjustments: job?.manualAdjustments ? job.manualAdjustments : 0,
          rhiInstalledQuantity: job?.rhiInstalledQuantity ? job.rhiInstalledQuantity : 0,
          customerCharge: job?.customerCharge ? job.customerCharge : "", //empty
        };
      }),
    };

    let errorMsg = [];
    let missingFieldsArray = [];
    for (const key of Object.keys(payload)) {
      if (
        key === "serviceJobId" ||
        key === "salesOrg" ||
        key === "currencyCode" ||
        key === "distributionChannel" ||
        key === "division" ||
        key === "crmReferenceId" ||
        key === "erpVendorId" ||
        key === "contractAccount" ||
        key === "assignedTo"
      ) {
        if (payload[key] === "" || payload[key] === undefined || payload[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    for (const key of Object.keys(payload.serviceAddress)) {
      if (key === "address1" || key === "city" || key === "state" || key === "postalCode" || key === "country") {
        if (payload.serviceAddress[key] === "" || payload.serviceAddress[key] === undefined || payload.serviceAddress[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      setComponent(null);
      return setTimeout(() => {
        notify(
          "Unable to update job status due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    } else if (accountDetails.resellerBusinessPartnerNumber === null && accountDetails.erpBpNumber === null) {
      setComponent(null);
      return setTimeout(() => {
        notify(
          "Unable to update job due to missing mandatory information (Mandatory: Reseller Business Partner Number). Kindly contact IT team for support",
          "error",
          false
        );
      }, 10);
    } else {
      payload?.itemDetails?.filter((data) => {
        for (const key of Object.keys(data)) {
          if (
            key === "materialLineId" ||
            key === "materialId" ||
            key === "itemDescription" ||
            key === "quantity" ||
            key === "processingCode" ||
            key === "lineItemUniqueId"
          ) {
            if (data[key] === "" || data[key] === null || data[key] === undefined) {
              captureMaterialId(key, data.materialId);
            }
          }
        }
      });
      if (emptyMaterialId.length > 0) errorMsg.push(` ${"Material Id"} is missing for ${emptyMaterialId.toString()}`);
      if (emptyMaterialLineId.length > 0) errorMsg.push(` ${"Material Line Id"} is missing for ${emptyMaterialLineId.toString()}`);
      if (emptyItemDescription.length > 0) errorMsg.push(` ${"Item Description"} is missing for ${emptyItemDescription.toString()}`);
      if (emptyQuantity.length > 0) errorMsg.push(` ${"Quantity"} is missing for ${emptyQuantity.toString()}`);
      if (emptyProcessingCode.length > 0) errorMsg.push(` ${"Processing Code"} is missing for ${emptyProcessingCode.toString()}`);
      if (emptyLineItemUniqueId.length > 0) errorMsg.push(` ${"LineItem Unique Id"} is missing for ${emptyLineItemUniqueId.toString()}`);

      if (errorMsg.length > 0) {
        setComponent(null);
        return setTimeout(() => {
          notify(
            <>
              {"Unable to update the job status due to below missing information. Kindly contact IT team for support. Missing Information: "}
              {errorMsg.map((msg) => (
                <div key={generate()}>{msg}</div>
              ))}
            </>,
            "error",
            false
          );
        }, 10);
      }
    }
    setLoader(true);
    let response = await completeJob(payload, jobDetails.jobId);
    if (response && response?.data?.statusCode === "200" && response?.data?.message?.toLowerCase() === "success") {
      let message = response?.data?.data?.message;
      setLoader(false);
      setComponent(null);
      notify(message, "success");
      switchTab(2, { data: { jobId: jobDetails.jobId } });
    } else if (response && response?.data?.statusCode === "404") {
      let message = response?.data?.message;
      setLoader(false);
      setComponent(null);
      response?.data?.data ? handleERPError(response?.data, notify) : notify(message, "error", false);
    } else {
      setLoader(false);
      setComponent(null);
      notify("Unable to update job status due to an unexpected error", "error", false);
    }
  };

  useEffect(() => {
    if (jobDetails.status.includes("Rescheduled")) {
      setNotification(
        <Notification
          type="warning"
          title={
            <>
              <span>
                You are completing a rescheduled job, ensure that no show fees are charged as appropriate. Please refer{" "}
                <TextLink
                  href={kbArticleLink}
                  size="small"
                  type="inline"
                  tabIndex="0"
                  role="link"
                  ariaLabel="kbArticleLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  KB Article
                </TextLink>
                .
              </span>
            </>
          }
          key={generate()}
          hideCloseButton={true}
          css={CustomisedNotification}
        />
      );
    }
  }, []);

  useEffect(() => {
    getMMIDList();
  }, []);

  const getMMIDList = () => {
    const data = jobDetails.jobDurationDetails;

    const tempNewJobDetails = data.filter(
      (data) =>
        data.processingCode === "ZTVF" ||
        data.processingCode === "ZTML" ||
        data.processingCode === "ZTEH" ||
        data.processingCode === "ZBTP" ||
        data.processingCode === "ZSPO" ||
        data.processingCode === "ZSP1"
    );
    setNewJobDetails(tempNewJobDetails);

    const mmidData = data.filter((data) => data.processingCode === "ZSPO" || data.processingCode === "ZSP1");
    setMmidData(mmidData);

    const installFeesData = data.filter((data) => data.processingCode === "ZTVF" || data.processingCode === "ZTML" || data.processingCode === "ZTEH");
    setInstallPartnerFeesData(installFeesData);

    const customerFeesData = data.filter((data) => data.processingCode === "ZBTP");
    setCustomerFeesData(customerFeesData);
  };

  useEffect(() => {
    if (selectedERPStatus && selectedERPSubStatus) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedERPStatus, selectedERPSubStatus]);

  useEffect(() => {
    if (selectedERPStatus.success && selectedERPSubStatus.success) {
      setStepsEnable({ ...stepsEnable, first: false });
    } else {
      setStepsEnable({ ...stepsEnable, first: true });
    }
  }, [selectedERPStatus, selectedERPSubStatus]);

  useEffect(() => {
    let partnerFeesErrorArr = [];
    installPartnerFees.installPartnerFees &&
      installPartnerFees.installPartnerFees?.forEach((obj) => {
        let allTrue = Object.entries(obj).filter((el) => {
          return el[1].success !== true;
        });
        if (allTrue.length) {
          partnerFeesErrorArr.push(obj);
        }
      });
    if (partnerFeesErrorArr.length === 0) {
      setStepsEnable({ ...stepsEnable, second: false });
    } else {
      setStepsEnable({ ...stepsEnable, second: true });
    }
  }, [installPartnerFees.installPartnerFees]);

  useEffect(() => {
    let customerFeesErrorArr = [];
    customerFees.customerFees &&
      customerFees.customerFees?.forEach((obj) => {
        let allTrue = Object.entries(obj).filter((el) => {
          return el[1].success !== true;
        });
        if (allTrue.length) {
          customerFeesErrorArr.push(obj);
        }
      });
    if (customerFeesErrorArr?.length === 0) {
      setStepsEnable({ ...stepsEnable, third: false });
    } else {
      setStepsEnable({ ...stepsEnable, third: true });
    }
  }, [customerFees.customerFees]);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setComponent(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const handleFields = (field, value, index = null) => {
    switch (field) {
      case "selectedERPStatus": {
        if (value) {
          setSelectedERPStatus({ value, error: false, errorText: "Select 1ERP Status", success: true });
        } else {
          setSelectedERPStatus({ value: "", error: true, errorText: "Select 1ERP Status", success: false });
        }
        break;
      }
      case "selectedERPSubStatus": {
        if (value) {
          setSelectedERPSubStatus({ value, error: false, errorText: "Select 1ERP Sub Status", success: true });
        } else {
          setSelectedERPSubStatus({ value: "", error: true, errorText: "Select 1ERP Sub Status", success: false });
        }
        break;
      }
    }
  };

  //for accessibility
  const stepModalFocus = () => {
    setTimeout(() => {
      dialogFocusRef.current.focus();
    }, 1000);
  };

  const nextStep = () => {
    incrementStep();
    setFormStep(formStep + 1);
  };

  const previousStep = () => {
    decrementStep();
    setFormStep(formStep - 1);
  };

  const closePopup = () => {
    // setReason("");
    installPartnerFees.setInstallPartnerFees([]);
    customerFees.setCustomerFees([]);
    totalCustomerFees.setTotalCustomerFees(0);
    setFormStep(0);
    // closeDialog();
    setComponent(null);
    // setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false });
  };
  const formStepper = () => {
    return (
      <Stepper step={step} animation={false}>
        <Step>
          <StepTitle>Complete Job</StepTitle>
        </Step>
        <Step>
          <StepTitle>Install Partner Fees</StepTitle>
        </Step>
        <Step>
          <StepTitle>Customer Fees</StepTitle>
        </Step>
        <Step>
          <StepTitle>Confirmation</StepTitle>
        </Step>
      </Stepper>
    );
  };

  const qtyChangeHandler = (e, job, index) => {
    let diff = job.quantity - job.rhiInstalledQty;
    let newQty = e.value;
    let updatedQty = newQty <= diff ? newQty : 0;
    let tempVal = [...mmidData];
    tempVal[index].manualAdjustQty = updatedQty;
    setMmidData(tempVal);
    if (newQty > diff) {
      document.activeElement.blur();
    }
  };
  const captureMaterialId = (materialName, materialId) => {
    switch (materialName) {
      case "materialLineId": {
        emptyMaterialLineId.push(materialId);
        break;
      }
      case "materialId": {
        emptyMaterialId.push(materialId);
        break;
      }
      case "itemDescription": {
        emptyItemDescription.push(materialId);
        break;
      }
      case "quantity": {
        emptyQuantity.push(materialId);
        break;
      }
      case "processingCode": {
        emptyProcessingCode.push(materialId);
        break;
      }
      case "lineItemUniqueId": {
        emptyLineItemUniqueId.push(materialId);
        break;
      }
    }
  };

  return (
    <Dialog
      visible={true}
      onHide={() => setComponent(null)}
      index={0}
      focusOnShow={true}
      breakpoints={{
        "960px": "90vw",
        "640px": "100vw",
        "700px": "98vw",
        "750px": "98vw",
        "800px": "98vw",
        "900px": "98vw",
        "950px": "98vw",
        "1000px": "98vw",
        "1050px": "98vw",
        "1100px": "98vw",
        "1150px": "98vw",
      }}
      style={{ width: "65vw" }}
    >
      <Loader active={isLoading} fullscreen={false} />
      {formStep === 0 ? (
        <>
          {formStepper()}
          <Spacer top="4vw" />
          <span ref={dialogFocusRef} tabIndex={0} style={{ fontSize: "2rem", fontWeight: 700, color: "#000" }}>
            Complete Job
          </span>
          <Spacer top="1vw" />
          <div css={{ height: "58vh", overflowY: "auto" }}>
            {notification}
            <Spacer top="0.5vw" />
            {systemUserNotification}

            <Spacer top="1vw" />
            <Body>If any installs from the scheduled job have not been completed yet, you need to schedule a new job.</Body>
            <Spacer top="1.2rem" />

            <FlexRow style={{ justifyContent: "space-between", padding: "5px", marginBottom: "10px" }}>
              <CustomDropdown
                width="50%"
                type="text"
                label="1ERP Status"
                aria-label="1ERP Status"
                value={selectedERPStatus.value || "Please select"}
                error={selectedERPStatus.error}
                errorText={selectedERPStatus.errorText}
                success={selectedERPStatus.success}
                onChange={(e) => handleFields("selectedERPStatus", e.target.value)}
                paddingRight="5px"
              >
                <>
                  <option value="">Please select</option>
                  {OneERPStatus.OneERPStatus.map((type) => (
                    <option key={type.code} value={type.code}>
                      {type.description}
                    </option>
                  ))}
                </>
              </CustomDropdown>
              <CustomDropdown
                width="50%"
                type="text"
                label="1ERP Sub Status"
                aria-label="1ERP Sub Status"
                value={selectedERPSubStatus.value || "Please select"}
                error={selectedERPSubStatus.error}
                errorText={selectedERPSubStatus.errorText}
                success={selectedERPSubStatus.success}
                onChange={(e) => handleFields("selectedERPSubStatus", e.target.value)}
                paddingRight="5px"
              >
                <>
                  <option value="">Please select</option>
                  {OneERPSubStatus.OneERPSubStatus.map((type) => (
                    <option key={type.code} value={type.code}>
                      {type.description}
                    </option>
                  ))}
                </>
              </CustomDropdown>
            </FlexRow>

            <FlexRow style={{ padding: "5px", marginBottom: "10px" }}>
              <Table tabIndex={0} role="table" topLine="none" css={CustomTableHeader}>
                <TableHead role="row">
                  <TableHeader role="columnheader" width="25%">
                    <SmallText bold>MMID</SmallText>
                  </TableHeader>
                  <TableHeader role="columnheader" width="40%">
                    <SmallText bold>Name</SmallText>
                  </TableHeader>
                  <TableHeader role="columnheader" width="40%">
                    <SmallText bold>Plan to install Quantity</SmallText>
                  </TableHeader>
                  <TableHeader role="columnheader" width="40%">
                    <SmallText bold>Installed Quantity-RHI</SmallText>
                  </TableHeader>
                  <TableHeader role="columnheader" width="20%">
                    <SmallText bold>Manual Adjustments</SmallText>
                  </TableHeader>
                </TableHead>
              </Table>
              {mmidData.length == 1 && (
                <div css={{ height: "8vh" }}>
                  <Loader active={isLoading} fullscreen={false} />
                  <Table role="table" topLine="none" striped={false} css={CustomTableHeader}>
                    <TableBody role="rowgroup">
                      {mmidData.map((job, index) => {
                        return (
                          <>
                            <TableRow role="row" key={job.materialId}>
                              <Cell role="cell" width="25%">
                                <SmallText>{job.materialId}</SmallText>
                              </Cell>
                              <Cell role="cell" width="40%">
                                <SmallText>{job.materialName}</SmallText>
                              </Cell>
                              <Cell role="cell" width="40%">
                                <SmallText>{job.quantity ? job.quantity : 0}</SmallText>
                              </Cell>
                              <Cell role="cell" width="40%">
                                <SmallText>{job.rhiInstalledQty ? job.rhiInstalledQty : 0}</SmallText>
                              </Cell>
                              <Cell role="cell" width="20%">
                                <InputNumber
                                  value={job.manualAdjustQty ? job.manualAdjustQty : 0}
                                  // value={job.manualAdjustQty <= 5 ? job.manualAdjustQty : 1}
                                  min={0}
                                  max={job.quantity - job.rhiInstalledQty}
                                  showButtons
                                  onChange={(e) => qtyChangeHandler(e, job, index)}
                                  css={CustomisedInput}
                                ></InputNumber>
                              </Cell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              )}
              {mmidData.length > 1 && (
                <div css={{ height: "15vh", overflowY: "auto" }}>
                  <Loader active={isLoading} fullscreen={false} />
                  <Table role="table" topLine="none" striped={false} css={CustomTableHeader}>
                    <TableBody role="rowgroup">
                      {mmidData.map((job, index) => {
                        return (
                          <>
                            <TableRow role="row" key={job.materialId}>
                              <Cell role="cell" width="25%">
                                <SmallText>{job.materialId}</SmallText>
                              </Cell>
                              <Cell role="cell" width="40%">
                                <SmallText>{job.materialName}</SmallText>
                              </Cell>
                              <Cell role="cell" width="40%">
                                <SmallText>{job.quantity ? job.quantity : 0}</SmallText>
                              </Cell>
                              <Cell role="cell" width="40%">
                                <SmallText>{job.rhiInstalledQty ? job.rhiInstalledQty : 0}</SmallText>
                              </Cell>
                              <Cell role="cell" width="20%">
                                <InputNumber
                                  value={job.manualAdjustQty ? job.manualAdjustQty : 0}
                                  // value={job.manualAdjustQty <= 5 ? job.manualAdjustQty : 1}
                                  min={1}
                                  max={job.quantity - job.rhiInstalledQty}
                                  showButtons
                                  onChange={(e) => qtyChangeHandler(e, job, index)}
                                  css={CustomisedInput}
                                ></InputNumber>
                              </Cell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              )}
            </FlexRow>

            <FlexRow style={{ justifyContent: "flex-start", paddingTop: "1rem" }}>
              <SLButton size="large" style={{ width: "10rem", marginBottom: "0px" }} disabled={stepsEnable.first} onClick={nextStep}>
                Next
              </SLButton>
              <SLButton size="large" secondary style={{ width: "10rem", marginBottom: "0px" }} onClick={closePopup}>
                Cancel
              </SLButton>
            </FlexRow>
          </div>
        </>
      ) : formStep === 1 ? (
        <>
          {formStepper()}
          <InstallPartnerFees
            formStep={formStep}
            previousStep={previousStep}
            nextStep={nextStep}
            stepsEnable={stepsEnable}
            closePopup={closePopup}
            installPartnerFeesData={installPartnerFeesData}
            stepModalFocus={stepModalFocus}
            descriptionList={descriptionList}
            showAddInstallerFees={true}
            jobDetails={jobDetails}
          />
        </>
      ) : formStep === 2 ? (
        <>
          {formStepper()}
          <CustomerFeesComp
            formStep={formStep}
            previousStep={previousStep}
            nextStep={nextStep}
            stepsEnable={stepsEnable}
            closePopup={closePopup}
            customerFeesData={customerFeesData}
            descriptionList={descriptionList}
            jobDetails={jobDetails}
            salesOrg={cspDetails?.cspDetails[0]?.Opp_Sales_Org ? cspDetails.cspDetails[0].Opp_Sales_Org : jobDetails?.salesOrg}
            notify={notify}
          />
        </>
      ) : formStep === 3 ? (
        <>
          {formStepper()}
          <ConfirmationComp
            formStep={formStep}
            previousStep={previousStep}
            closePopup={closePopup}
            source={"Complete"}
            completeJobs={completeJobs}
            jobDetails={jobDetails}
          />
        </>
      ) : (
        <></>
      )}
    </Dialog>
  );
};
export default CompleteJob;
