import React, { useState, useEffect, useContext } from "react";
import { Grid, Row } from "@vds/grids";
import { SLGridLayout, SLRowLayout } from "../../components/core/Utility/index";
import { CompactCol, CustomSLButton, FlexRow, SmallText, UltraMediumText } from "../../components/core/utility";
import { CustomInput, StyledCol, ColumnAlignment, CustomDropdown } from "./AddAVehicle";
import { RadioButtonGroup } from "@vds/inputs";
import styled from "styled-components";
import { ADDRESS_REGEX, ZIP_CODE_LIMIT, ZIP_REGEX } from "../../constants/C4SValidationConstants";
import { css } from "@emotion/react";
import { isEqual, groupBy } from "lodash";
import { validateAddress } from "../../services/C4S Decommission - Replacement/validateAddress";
import { getStatesList } from "../../services/C4S Decommission - Replacement/getStatesList";
import { getCountryList } from "../../services/scheduler/getCountryList";
import { Notification } from "@vds/notifications";
import { generate } from "shortid";
import { C4SContext } from "../../context/c4sContext";
import useOutsideClick from "../../customHook/use-outside-click";

const RadioButtonGroupWrapper = styled.div`
  div[role="radiogroup"] {
    border: 0.0625rem solid #d8dada;
    width: 70vmin;
    margin-bottom: 0.6rem;
    > div > label {
      > div:first-child > div {
        height: 1rem;
        width: 1rem;
        transform: translate(50%, 100%);
      }
      > div:last-child {
        font-size: 12px;
        font-family: Verizon-NHG-eTX;
        padding: 0.313rem 0.5rem;
        > span > span {
          font-size: 14px;
          font-family: Verizon-NHG-eTX;
        }
      }
    }
  }
`;

const InstallationAddressRow = css`
  @media screen and (min-width: 910px) {
    // width: 50%;
    max-width: 37.25rem;
    min-width: 4.125rem;
  }
`;

export const ValidateAddress = ({ address, setAddress, vehicleData, showHistoricalAddressDropdown, setLoader, title = "", subTitle = "" }) => {
  const { notification, historicalAddressDetails, countryListDetails, stateListDetails } = useContext(C4SContext);
  const { verified, addressByPass, locationFound, historicalAddress, address1, address2, city, state, zip, country } = address;
  const [isAddressSuggestionShown, setIsAddressSuggestionShown] = useState(false);
  const [isAddressFieldsDisable, setIsAddressFieldsDisable] = useState(showHistoricalAddressDropdown);
  const [suggestedAddress, setSuggestedAddress] = useState({
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  const [typedAddress, setTypedAddress] = useState("");
  const [isAddressValidationBtnDisabled, setIsAddressValidationBtnDisabled] = useState(true);
  const [zipCodeLimit, setZipCodeLimit] = useState("");
  const [zipRegex, setZipRegex] = useState("");
  const countryZipLimitsList = {
    DEU: [5, 5],
    GBR: [4, 8],
    FRA: [5, 5],
    CAN: [6, 6],
    NLD: [4, 4],
    IRL: [7, 7],
    MEX: [5, 5],
    NZL: [4, 4],
    USA: [5, 5],
    AUS: [4, 4],
    AUT: [4, 4],
    BEL: [4, 4],
  };
  const handleClickOutside = () => {
    notification.setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);

  useEffect(() => {
    if (address1.success && !address2.error && city.success && state.success && country.success && zip.success) {
      if (verified) {
        historicalAddress.value.includes("Please")
          ? isAddressFieldsDisable && setIsAddressFieldsDisable(false)
          : showHistoricalAddressDropdown && !isAddressFieldsDisable && setIsAddressFieldsDisable(true);
      } else {
        if (vehicleData?.id && addressByPass === "NeedToValidateOnLoad") {
          if (!vehicleData?.addressByPass) {
            handleAddressVerification(address);
          } else {
            setAddress((prevState) => ({
              ...prevState,
              verified: true,
              addressByPass: vehicleData.addressByPass,
              locationFound: vehicleData.locationFound,
            }));
          }
        } else {
          if (isAddressSuggestionShown) {
            showHistoricalAddressDropdown && !isAddressFieldsDisable && setIsAddressFieldsDisable(true);
            !isAddressValidationBtnDisabled && setIsAddressValidationBtnDisabled(true);
            if (!suggestedAddress.address && isAddressSuggestionShown) {
              setIsAddressSuggestionShown(false);
              setAddress((prevState) => ({ ...prevState, verified: true }));
            }
          } else if (!historicalAddress.value.includes("Please") && historicalAddress.success) {
            handleAddressVerification(address);
          } else {
            isAddressFieldsDisable && setIsAddressFieldsDisable(false);
            isAddressValidationBtnDisabled && setIsAddressValidationBtnDisabled(false);
          }
        }
      }
    } else {
      if (addressByPass === "NeedToValidateOnLoad") {
        setAddress((prevState) => ({
          ...prevState,
          addressByPass: null,
        }));
      } else if (!suggestedAddress.address && isAddressSuggestionShown) {
        setIsAddressSuggestionShown(false);
        setAddress((prevState) => ({ ...prevState, verified: false }));
      } else {
        if (!historicalAddress.value) {
          showHistoricalAddressDropdown && !isAddressFieldsDisable && setIsAddressFieldsDisable(true);
        } else {
          !historicalAddress.value.includes("Please") &&
            setAddress((prevState) => ({ ...prevState, historicalAddress: { ...prevState.historicalAddress, error: true, success: false } }));
          isAddressFieldsDisable && setIsAddressFieldsDisable(false);
          !isAddressValidationBtnDisabled && setIsAddressValidationBtnDisabled(true);
        }
      }
    }
  }, [
    verified,
    historicalAddress.value,
    addressByPass,
    address1.value,
    address2.value,
    country.value,
    city.value,
    state.value,
    zip.value,
    suggestedAddress.address,
  ]);

  useEffect(() => {
    handleAddress();
  }, []);

  const notify = (content, type) => {
    notification.setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
  };

  const getCountries = async () => {
    let countryOptions = [],
      allCountryStateOptions = null;
    let countryListResponse = countryListDetails.countryListDetails ? countryListDetails.countryListDetails : await getCountryList();
    if (countryListResponse.status === 200 && countryListResponse?.data?.data?.length) {
      countryOptions = countryListResponse.data.data;
      countryListDetails.setCountryListDetails(countryListResponse);
      ({ allCountryStateOptions } = await getStateList());
    } else {
      notify("Unable to fetch country list due to an unexpected error", "error");
    }
    setAddress((prevState) => ({
      ...prevState,
      country: {
        ...prevState.country,
        options: countryOptions,
        error: !countryOptions.length,
        errorText: countryOptions.length ? prevState.country.errorText : "Unable to fetch country list due to an unexpected error",
        success: false,
      },
      state: {
        ...prevState.state,
        options: [],
        allStateOptions: allCountryStateOptions,
        error: !allCountryStateOptions,
        errorText: allCountryStateOptions ? prevState.state.errorText : "Unable to fetch state list due to an unexpected error",
        success: false,
      },
    }));
    return { countryOptions, allCountryStateOptions };
  };

  const getStateList = async () => {
    let allCountryStateOptions = null;
    let stateListResponse = stateListDetails.stateListDetails ? stateListDetails.stateListDetails : await getStatesList();
    if (stateListResponse.status === 200 && stateListResponse?.data?.data?.length) {
      stateListDetails.setStateListDetails(stateListResponse);
      allCountryStateOptions = stateListResponse.data.data.map((state) => ({
        ...state,
        stateName: state.erpStateCode + " - " + state.stateCode,
      }));
      allCountryStateOptions = groupBy(allCountryStateOptions, "countryCode");
    } else {
      notify("Unable to fetch state list due to an unexpected error", "error");
    }
    return { allCountryStateOptions };
  };

  const handleAddress = async () => {
    setLoader(true);
    let countryOptions = [],
      allCountryStateOptions = null;
    if (address.country.value && address.country.options.length) {
      countryOptions = address.country.options;
      handleZipCodeBasedOnCountry(address.country.value.code);
      if (address.state.value && address.state.options.length && address.state.allStateOptions) {
        allCountryStateOptions = address.state.allStateOptions;
      }
    } else {
      ({ countryOptions, allCountryStateOptions } = await getCountries());
    }
    setLoader(false);
    if (vehicleData && Object.keys(vehicleData).length) {
      setAddress((prevState) => ({
        ...prevState,
        historicalAddress: { value: "Please select an address or choose Add New to add a new address", error: false, errorText: "", success: true },
      }));
      handleAddressFields("Address 1", vehicleData.addressOne?.trim());
      handleAddressFields("Address 2", vehicleData.addressTwo?.trim());
      handleAddressFields("City", vehicleData.city?.trim());
      handleAddressFields(
        "Country",
        vehicleData.country?.trim(),
        vehicleData.stateCode?.trim(),
        vehicleData.zipCode?.trim(),
        countryOptions,
        allCountryStateOptions
      );
      setAddress((prevState) => ({
        ...prevState,
        addressByPass: "NeedToValidateOnLoad",
      }));
    }
  };

  const handleAddressFields = (
    field,
    value,
    customValue1 = false,
    customValue2 = false,
    countryOptions = false,
    allCountryStateOptions = false,
    trim = false
  ) => {
    if (!trim) {
      if (verified) {
        setAddress((prevState) => ({ ...prevState, verified: false }));
      }
    }
    switch (field) {
      case "Address 1": {
        if (value && value.length && value.toLowerCase() !== "null") {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: false, errorText: "Enter address 1", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: true, errorText: "Enter a valid address 1", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address1: { value: "", error: true, errorText: "Enter address 1", success: false } }));
        }
        break;
      }
      case "Address 2": {
        if (value && value.length && value.toLowerCase() !== "null") {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: false, errorText: "Enter address 2", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: true, errorText: "Enter a valid address 2", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address2: { value: "", error: false, errorText: "Enter address 2", success: false } }));
        }
        break;
      }
      case "City": {
        value && value.length && value.toLowerCase() !== "null"
          ? ADDRESS_REGEX.test(value)
            ? setAddress((prevState) => ({ ...prevState, city: { ...prevState.city, value, error: false, errorText: "Enter city", success: true } }))
            : setAddress((prevState) => ({
                ...prevState,
                city: { ...prevState.city, value, error: true, errorText: "Enter valid city", success: false },
              }))
          : setAddress((prevState) => ({
              ...prevState,
              city: { ...prevState.city, value: "", error: true, errorText: "Enter city", success: false },
            }));
        break;
      }
      case "State": {
        value = customValue1.find((state) => value === state.erpStateCode || value?.toLowerCase() === state.stateCode.toLowerCase()) ?? null;
        if (value) {
          setAddress((prevState) => ({
            ...prevState,
            state: { ...prevState.state, value, error: false, errorText: "Please select state", success: true },
          }));
        } else {
          setAddress((prevState) => ({
            ...prevState,
            state: { ...prevState.state, value: null, error: true, errorText: "Please select state", success: false },
          }));
        }
        break;
      }
      case "Zip": {
        value = value.replace(/[^a-zA-Z0-9 ]/g, "");
        if (value && value.length && value.toLowerCase() !== "null") {
          if (customValue1.test(value)) {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: false, errorText: "Enter zip code", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: true, errorText: "Enter a valid zip code", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, zip: { value: "", error: true, errorText: "Enter zip code", success: false } }));
        }
        break;
      }
      case "Country": {
        countryOptions = countryOptions || country.options;
        let stateOptions = allCountryStateOptions || state.allStateOptions;
        value =
          countryOptions.find(
            (country) => country.code.toLowerCase() === value?.toLowerCase() || country.description.toLowerCase() === value?.toLowerCase()
          ) ?? null;
        let { zipCodeRegex } = handleZipCodeBasedOnCountry(value?.code ?? null);
        if (value) {
          stateOptions = stateOptions ? stateOptions[value.code] : [];
          setAddress((prevState) => ({
            ...prevState,
            country: { ...prevState.country, value, error: false, errorText: "Please select country", success: true },
            state: {
              ...prevState.state,
              value: null,
              options: stateOptions,
              error: !stateOptions.length || prevState.state.value || prevState.state.error ? true : false,
              success: false,
            },
          }));
          stateOptions.length && customValue1 && handleAddressFields("State", customValue1, stateOptions);
        } else {
          setAddress((prevState) => ({
            ...prevState,
            country: {
              ...prevState.country,
              value: null,
              error: true,
              errorText: prevState.country.error ? prevState.country.errorText : "Please select country",
              success: false,
            },
            state: {
              ...prevState.state,
              value: null,
              options: [],
              error: prevState.state.value || prevState.state.error ? true : false,
              success: false,
            },
          }));
        }
        customValue2 ? handleAddressFields("Zip", customValue2, zipCodeRegex) : zip.value && handleAddressFields("Zip", zip.value, zipCodeRegex);
        break;
      }
    }
  };

  const handleZipCodeBasedOnCountry = (country) => {
    let zipCodeRegex = ZIP_REGEX,
      zipCodeLimit = ZIP_CODE_LIMIT;
    if (country) {
      let limits = countryZipLimitsList[country];
      (zipCodeRegex = new RegExp(`^\\d{${limits[0]},${limits[1]}}$`)), (zipCodeLimit = limits[1]);
      if (["GBR", "CAN", "IRL", "NLD"].includes(country)) {
        if (country === "IRL") {
          zipCodeRegex = new RegExp("^[a-zA-Z0-9]{3} [a-zA-Z0-9]{4}$");
          zipCodeLimit = limits[1] + 1;
        }
        if (country === "CAN") {
          zipCodeRegex = new RegExp("^[a-zA-Z]\\d[a-zA-Z] \\d[a-zA-Z]\\d$");
          zipCodeLimit = limits[1] + 1;
        }
        if (country === "GBR") {
          zipCodeRegex = new RegExp(`^[a-zA-Z0-9 ]{${limits[0]},${limits[1]}}$`);
          zipCodeLimit = limits[1];
        }
        if (country === "NLD") {
          zipCodeRegex = new RegExp("^[0-9]{4} [a-zA-Z]{2}$");
          zipCodeLimit = 7;
        }
      } else {
        zipCodeLimit = limits[1];
      }
    }
    setZipRegex(zipCodeRegex);
    setZipCodeLimit(zipCodeLimit);
    return { zipCodeRegex };
  };

  const splitAddress = (address) => {
    const addressParts = address.split(",");
    let address1 = "";
    let address2 = "";
    let city = "";
    let state = "";
    let zipCode = "";
    let country = "";
    if (addressParts.length <= 6) {
      address1 = addressParts[0].trim();
      city = addressParts[addressParts.length - 4].trim();
      state = addressParts[addressParts.length - 3].trim();
      zipCode = addressParts[addressParts.length - 2].trim();
      country = addressParts[addressParts.length - 1].trim();
      if (addressParts.length > 5) {
        address2 = addressParts.slice(1, -4).join(",").trim();
      }
    }
    const addressObj = { address1, address2, city, state, zipCode, country };
    return addressObj;
  };

  const handleHistoricalAddressSelection = (addressSelected) => {
    if (addressSelected === "Add New") {
      setAddress((prevState) => ({
        ...prevState,
        address1: { value: "", error: false, errorText: "Enter address 1", success: false },
        address2: { value: "", error: false, errorText: "Enter address 2", success: false },
        city: { value: "", error: false, errorText: "Enter city", success: false },
        state: {
          ...prevState.state,
          value: null,
          options: [],
          error: !prevState.state.allStateOptions,
          errorText: prevState.state.options.length ? "Please select state" : prevState.state.errorText,
          success: false,
        },
        zip: { value: "", error: false, errorText: "Enter zip code", success: false },
        country: {
          ...prevState.country,
          value: null,
          error: !prevState.country.options.length,
          errorText: prevState.country.options.length ? "Please select country" : prevState.country.errorText,
          success: false,
        },
        historicalAddress: { value: "Please select an address or choose Add New to add a new address", error: false, errorText: "", success: false },
        verified: false,
      }));
    } else {
      let splittedAddress = splitAddress(addressSelected);
      handleAddressFields("Address 1", splittedAddress.address1);
      handleAddressFields("Address 2", splittedAddress.address2);
      handleAddressFields("City", splittedAddress.city);
      handleAddressFields("Country", splittedAddress.country, splittedAddress.state, splittedAddress.zipCode);
      setAddress((prevState) => ({
        ...prevState,
        historicalAddress: { value: addressSelected, error: false, errorText: "", success: true },
        verified: false,
      }));
    }
  };

  const handleAddressVerification = async (address) => {
    setLoader(true);
    showHistoricalAddressDropdown && !isAddressFieldsDisable && setIsAddressFieldsDisable(true);
    !isAddressValidationBtnDisabled && setIsAddressValidationBtnDisabled(true);
    let data = {
      inputAddress: {
        addressLine1: address?.address1?.value,
        addressLine2: address?.address2?.value ?? "",
        city: address?.city?.value,
        stateProvince: address?.state?.value?.erpStateCode,
        postalCode: address?.zip?.value,
        country: address?.country?.value?.code,
      },
    };
    let response = await validateAddress(data),
      timezone = "";
    if (response?.status === 200 && response?.data?.message.toLowerCase() === "success") {
      let responsedata = response?.data?.data,
        addressByPass = responsedata?.addressByPass,
        locationFound = responsedata?.locationFound,
        stdAddress = responsedata?.standardizedInfo?.standardizedAddress,
        userAddress = responsedata?.inputAddress,
        standardAddress = {
          address1: stdAddress?.addressLine1?.toLowerCase(),
          address2: stdAddress?.subLocation?.toLowerCase() ?? "",
          city: stdAddress?.city?.toLowerCase(),
          state: stdAddress?.stateProvince?.toLowerCase(),
          zip: stdAddress?.postalCodeBase?.toLowerCase(),
          country: stdAddress?.iso3CountryCode?.toLowerCase(),
        },
        userTypedAddress = {
          address1: userAddress?.addressLine1?.toLowerCase(),
          address2: userAddress?.addressLine2?.toLowerCase() ?? "",
          city: userAddress?.city?.toLowerCase(),
          state: userAddress?.stateProvince?.toLowerCase(),
          zip: userAddress?.postalCode?.toLowerCase(),
          country: userAddress?.country?.toLowerCase(),
        },
        isExactMatch = isEqual(standardAddress, userTypedAddress);
      timezone = responsedata?.location?.timezone ?? "";
      if (addressByPass === "YES") {
        setAddress((prevState) => ({ ...prevState, verified: true }));
        if (!locationFound) {
          notify("Please note that the entered address is not verified and couldn't find a close match", "warning");
        }
      } else {
        if (locationFound) {
          if (isExactMatch) {
            setAddress((prevState) => ({ ...prevState, verified: true }));
          } else {
            setIsAddressSuggestionShown(true);
            setSuggestedAddress({
              address: `${stdAddress?.addressLine1}, ${stdAddress?.subLocation ? stdAddress?.subLocation + ", " : ""}${stdAddress?.city}, ${
                stdAddress?.stateProvince
              }, ${stdAddress?.postalCodeBase}, ${stdAddress?.iso3CountryCode}`,
              address1: stdAddress?.addressLine1,
              address2: stdAddress?.subLocation,
              city: stdAddress?.city,
              state: stdAddress?.stateProvince,
              zip: stdAddress?.postalCodeBase,
              country: stdAddress?.iso3CountryCode,
            });
            setTypedAddress(
              `${userAddress.addressLine1}, ${userAddress.addressLine2 ? userAddress.addressLine2 + ", " : ""}${userAddress.city}, ${
                userAddress.stateProvince
              }, ${userAddress.postalCode}, ${userAddress.country}`
            );
          }
        } else {
          notify("We couldn't verify the entered address and couldn't find a close match", "error");
        }
      }
      setAddress((prevState) => ({ ...prevState, addressByPass, locationFound, timezone }));
    } else {
      setAddress((prevState) => ({ ...prevState, timezone }));
      notify("Address validation failed due to an unexpected error", "error");
    }
    setLoader(false);
  };

  const handleAddressSelection = (verified = false) => {
    if (verified) {
      handleAddressFields("Address 1", suggestedAddress.address1);
      handleAddressFields("Address 2", suggestedAddress.address2);
      handleAddressFields("City", suggestedAddress.city);
      handleAddressFields("Country", suggestedAddress.country, suggestedAddress.state, suggestedAddress.zip);
    }
    setSuggestedAddress((prevState) => ({
      ...prevState,
      address: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    }));
    setTypedAddress("");
  };

  return (
    <>
      <SLGridLayout>
        <SLRowLayout>
          <UltraMediumText css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
            {title ? title : `Shipping Address`}
          </UltraMediumText>
        </SLRowLayout>
      </SLGridLayout>
      {subTitle && (
        <SLGridLayout>
          <SLRowLayout>
            <UltraMediumText css={{ marginTop: "1rem", fontWeight: "normal" }} eDSFont fontSize="14px" marginBottom="0.7rem">
              {subTitle}
            </UltraMediumText>
          </SLRowLayout>
        </SLGridLayout>
      )}
      {showHistoricalAddressDropdown && (
        <SLGridLayout>
          <SLRowLayout css={{ marginTop: "1.25rem" }}>
            <div css={InstallationAddressRow}>
              <CustomDropdown
                type="text"
                disabled={!country.options.length || !state.allStateOptions}
                aria-label={historicalAddress.value ? historicalAddress.value : "Please select an address or choose Add New to add a new address"}
                value={historicalAddress.value ? historicalAddress.value : "Please select an address or choose Add New to add a new address"}
                onChange={(event) => handleHistoricalAddressSelection(event.target.value)}
              >
                {historicalAddressDetails.historicalAddressDetails.length > 0 ? (
                  <>
                    <option disabled>Please select an address or choose Add New to add a new address</option>
                    {historicalAddressDetails.historicalAddressDetails.map((address) => (
                      <option key={generate()}>{address}</option>
                    ))}
                    <option>Add New</option>
                  </>
                ) : (
                  <>
                    <option disabled>Please select an address or choose Add New to add a new address</option>
                    <option disabled>No Address Available</option>
                    <option>Add New</option>
                  </>
                )}
              </CustomDropdown>
            </div>
          </SLRowLayout>
        </SLGridLayout>
      )}
      <SLGridLayout>
        <SLRowLayout css={{ marginTop: "1.25rem" }}>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <CustomInput
              type="text"
              label="Address 1"
              aria-label="Address 1"
              error={address1.error}
              errorText={address1.errorText}
              success={address1.success}
              value={address1.value}
              disabled={isAddressFieldsDisable}
              onChange={(e) => handleAddressFields("Address 1", e.target.value)}
              onBlur={(e) => handleAddressFields("Address 1", e.target.value?.trim(), false, false, false, false, true)}
            />
          </CompactCol>
        </SLRowLayout>
        <SLRowLayout>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <CustomInput
              type="text"
              label="Address 2 (Optional)"
              aria-label="Address 2 (Optional)"
              error={address2.error}
              errorText={address2.errorText}
              success={address2.success}
              value={address2.value}
              disabled={isAddressFieldsDisable}
              onChange={(e) => handleAddressFields("Address 2", e.target.value)}
              onBlur={(e) => handleAddressFields("Address 2", e.target.value?.trim(), false, false, false, false, true)}
            />
          </CompactCol>
        </SLRowLayout>
        <SLRowLayout>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }} css={StyledCol}>
            <SLGridLayout>
              <SLRowLayout>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                  <CustomInput
                    width="100%"
                    type="text"
                    label="City"
                    aria-label="City"
                    paddingRight="5px"
                    error={city.error}
                    errorText={city.errorText}
                    success={city.success}
                    value={city.value}
                    disabled={isAddressFieldsDisable}
                    onChange={(e) => handleAddressFields("City", e.target.value)}
                    css={ColumnAlignment}
                    onBlur={(e) => handleAddressFields("City", e.target.value?.trim(), false, false, false, false, true)}
                  />
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                  <CustomDropdown
                    width="100%"
                    type="text"
                    label="Country"
                    aria-label="Country"
                    hideErrorImg={country.error}
                    paddingRight="5px"
                    css={ColumnAlignment}
                    error={country.error}
                    errorText={country.errorText}
                    success={country.success}
                    value={country.value?.code || "Please Select"}
                    disabled={isAddressFieldsDisable}
                    onChange={(e) => handleAddressFields("Country", e.target.value, false, false)}
                  >
                    <>
                      <option disabled>Please Select</option>
                      {country.options.map((country) => (
                        <option key={country.code}>{country.code}</option>
                      ))}
                    </>
                  </CustomDropdown>
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                  <CustomDropdown
                    width="100%"
                    paddingRight="5px"
                    type="text"
                    label="State"
                    aria-label="State"
                    disabled={isAddressFieldsDisable || !country.value}
                    hideErrorImg={state.error}
                    error={state.error}
                    errorText={state.errorText}
                    value={state.value?.stateName || "Please Select"}
                    onChange={(e) => handleAddressFields("State", e.target.value.split(" - ")[0], state.options)}
                  >
                    <>
                      <option disabled style={{ backgroundColor: "lightgrey" }}>
                        Please Select
                      </option>
                      {state.options.map((stateCode) => (
                        <option key={stateCode.stateCode + stateCode.countryCode}>{stateCode.stateName}</option>
                      ))}
                    </>
                  </CustomDropdown>
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                  <CustomInput
                    width="100%"
                    type="text"
                    label="Zip/Postal Code"
                    aria-label="Zip"
                    error={zip.error}
                    errorText={zip.errorText}
                    value={zip.value}
                    success={zip.success}
                    disabled={isAddressFieldsDisable || !country.value}
                    onChange={(e) => handleAddressFields("Zip", e.target.value, zipRegex)}
                    maxLength={zipCodeLimit}
                    onBlur={(e) => handleAddressFields("Zip", e.target.value?.trim(), zipRegex, false, false, false, true)}
                  />
                </CompactCol>
              </SLRowLayout>
            </SLGridLayout>
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>
      <FlexRow>
        {!isAddressFieldsDisable && !verified && (
          <CustomSLButton
            aria-label="Validate Address"
            role="button"
            onClick={() => handleAddressVerification(address)}
            disabled={isAddressValidationBtnDisabled}
            aria-disabled={isAddressValidationBtnDisabled}
          >
            Validate Address
          </CustomSLButton>
        )}
      </FlexRow>
      {isAddressSuggestionShown && (
        <>
          <UltraMediumText eDSFont fontSize="18px" marginBottom="0" marginTop="1rem">
            Choose an option
          </UltraMediumText>
          <SmallText>We couldn&apos;t verify the entered address. We found a close match</SmallText>
          <RadioButtonGroupWrapper>
            <RadioButtonGroup
              onChange={() => handleAddressSelection(true)}
              error={false}
              data={[
                {
                  name: "group",
                  label: (
                    <>
                      <span>Use suggested address</span>
                      <mark css={{ fontSize: "10px", backgroundColor: "#d8dada", padding: "0rem 0.313rem", marginLeft: "0.313rem" }}>Verified</mark>
                    </>
                  ),
                  children: suggestedAddress.address,
                  value: "radioOne",
                  ariaLabel: "Use suggested address",
                },
              ]}
            />
            <RadioButtonGroup
              onChange={() => handleAddressSelection()}
              error={false}
              data={[
                {
                  name: "group",
                  label: (
                    <>
                      <span>Use address as typed</span>
                    </>
                  ),
                  children: typedAddress,
                  value: "radioTwo",
                  ariaLabel: "Use address as typed",
                },
              ]}
            />
          </RadioButtonGroupWrapper>
        </>
      )}
    </>
  );
};
