import { getRequestWithPathVariable } from "../RestServices";
import * as urls from "../../api/orders/url";

export const getShipmentEventsByOpp = async (salesforceOpportunityId) => {
  try {
    const _response = await getRequestWithPathVariable(urls.getShipmentEventsByOpp, salesforceOpportunityId);
    const _ShipmentEvents = _response?.data?.data ?? [];
    return { rows: _ShipmentEvents };
  } catch (err) {
    return err;
  }
};
