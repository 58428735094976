import React, { useState, useContext, useEffect, useRef } from "react";
import { SLButton } from "../../../components/core/Controls";
import { FlexRow, Spacer, SmallText } from "../../../components/core/Utility/index";
import "./../Stepper.css";
import { CustomDropdown, CustomInputDynamicWidth } from "./../CreateNewJob";
import { AccountDetailsContext } from "../../../context/accountDetailsContext";
import { Notification } from "@vds/notifications";
import { generate } from "shortid";
import { css } from "@emotion/react";
import { Table, TableHead, TableBody, TableRow, Cell, TableHeader } from "@vds/tables";
import { Loader } from "@vds/loaders";
import Select from "react-select";
import { Icon } from "@vds/icons";
import styled from "@emotion/styled";
import { Tooltip } from "@vds/tooltips";
import xlsIcon from "../../../assets/icons/export-xls.png";
import moment from "moment";
import { COLUMN_WIDTHS } from "../../../constants/ApplicationConstants";
import { getProcessingCodes } from "../../../services/scheduler/getProcessingCodes";
import useOutsideClick from "../../../customHook/use-outside-click";

const CustomTableHeader = css`
  margin-bottom: 0px;
  table-layout: fixed;
  thead {
    /* border-bottom: 2px solid #000000; */
    border-bottom: none;
    margin-bottom: 3px;
    th {
      padding: 1rem 0rem 0.75rem 0rem;
      /* padding: 1rem 0.5rem 0.75rem 0.5rem; */
    }
  }
  tr {
    border-bottom: none;
    td {
      padding: 8px 2px 5px 0px;
      /* padding: 8px 2px 5px 15px; */
    }
  }
`;
const CustomSelectCSS = css`
  input {
    min-width: 55vh !important;
  }
`;

export const IconWrapper = styled.div`
  :focus {
    border: 1px solid black !important;
    outline: none;
    box-shadow: none;
  }
  height: 24px;
  width: 24px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
`;
const CustomTooltip = styled(Tooltip)`
  > span > button > span:nth-child(2) > span {
    width: 24px;
    height: 24px;
    left: 8px;
  }
  > span > button > span:nth-child(2) > svg {
    display: none;
  }
  > span > button > span:nth-child(3) > span {
    width: max-content;
    left: 8px;
    > span:nth-child(2) {
      width: 24px;
      height: 24px;
      left: 12px;
    }
  }
`;

const InstallPartnerFees = ({
  formStep,
  previousStep,
  nextStep,
  stepsEnable,
  closePopup,
  installPartnerFeesData,
  jobDetails,
  stepModalFocus,
  descriptionList,
  showAddInstallerFees,
}) => {
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState();
  const feeNotification = useRef(false);
  const { installPartnerFeesDesc, cspDetails, installPartnerFees } = useContext(AccountDetailsContext);
  const [processingCodes, setProcessingCodes] = useState([]);
  let appointmentDate = new Date(jobDetails.scheduledDate + " " + jobDetails.scheduledTime).getTime();
  const customStyle = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid gray" : "",
      // This line disable the blue border
      boxShadow: state.isFocused ? "1px solid gray" : "",
      "&:hover": {
        border: state.isFocused ? "1px solid gray" : "",
      },
    }),
  };
  const handleClickOutside = () => {
    if (feeNotification.current === false) setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  const columns = [
    {
      key: "Label",
      value: "label",
      width: COLUMN_WIDTHS.large,
    },
    {
      key: "Value",
      value: "value",
      width: COLUMN_WIDTHS.large,
    },
    {
      key: "Text",
      value: "text",
      width: COLUMN_WIDTHS.large,
    },
    {
      key: "Unit",
      value: "baseMeasurementUnit",
    },
  ];

  const exportExcel = () => {
    var dataToExport = descriptionList;
    dataToExport = formatData(dataToExport);
    if (dataToExport.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(getDataForExport(dataToExport));
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
        saveFile(excelBuffer, "data", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8", ".xlsx");
      });
    }
  };

  const getDataForExport = (data) => {
    return data.map((item) => {
      return Object.fromEntries(
        columns.map((col) => [
          col.key,
          typeof item[col.value] === "object" || Array.isArray(item[col.value]) ? JSON.stringify(item[col.value]) : item[col.value],
        ])
      );
    });
  };

  const formatData = (dataToExport) => {
    let dataColumns = columns.filter((item) => {
      return item.isDate;
    });
    return dataToExport.map((item) => {
      dataColumns.forEach((columnItem) => {
        item[columnItem.value] = formatDate(item[columnItem.value], columnItem.dateFormat);
      });
      return item;
    });
  };
  const saveFile = (buffer, fileName, type, extension) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        const data = new Blob([buffer], {
          type: type,
        });

        module.default.saveAs(data, fileName + extension);
      }
    });
  };
  const formatDate = (value, dateFormat, timezone = "") => {
    if (!value) {
      return formattedData(value);
    }
    if (dateFormat) {
      return `${moment(value).format(dateFormat)} ${timezone}`.trim();
    }
    return value?.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };
  const formattedData = (data) => {
    return data == null ? "--" : data;
  };
  const handleSelectChange = (option) => {
    addMoreFees(option);
  };

  const deleteRow = (index) => {
    const tempArray = installPartnerFees.installPartnerFees.filter((item, i) => i !== index);
    installPartnerFees.setInstallPartnerFees(tempArray);
  };

  const getProcessingCodesData = async () => {
    setLoader(true);
    let processCodesResponse = await getProcessingCodes("VendorFee");
    if (processCodesResponse.status === 200 && processCodesResponse.data?.data?.length) {
      setProcessingCodes(processCodesResponse.data.data);
      setLoader(false);
    } else {
      setLoader(false);
      notifyError("Unable to fetch Processing Codes due to an unexpected error.", "error");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  //for accessibility
  const addFeesref = useRef();
  const InstallPartnerFeesTitle = useRef();

  useEffect(() => {
    //for accessibility
    InstallPartnerFeesTitle.current.focus();

    getProcessingCodesData();
  }, []);

  const handleFields = (field, value, index = null) => {
    switch (field) {
      case "itemDescription": {
        let tempFees = [...installPartnerFees.installPartnerFees];
        const tempData = installPartnerFeesDesc && installPartnerFeesDesc?.installPartnerFeesDesc.filter((data) => data.materialName === value);
        const data = tempData[0].materialId;
        if (value && value.length) {
          tempFees[index][field] = { value, error: false, errorText: "Select Description", success: true };
        } else {
          tempFees[index][field] = { value: "", error: true, errorText: "Select Description", success: false };
        }
        tempFees[index]["mmid"] = { value: data, error: false, errorText: "Enter MMID", success: true };
        tempFees[index]["unit"] = { value: tempData[0].baseMeasurementUnit, error: false, errorText: "Enter Unit of Measure", success: true };
        installPartnerFees.setInstallPartnerFees(tempFees);
        break;
      }

      case "mmid": {
        let tempFees = [...installPartnerFees.installPartnerFees];
        if (value && value.length) {
          tempFees[index][field] = { value, error: false, errorText: "Enter MMID", success: true };
        } else {
          tempFees[index][field] = { value: "", error: true, errorText: "Enter MMID", success: false };
        }
        installPartnerFees.setInstallPartnerFees(tempFees);
        break;
      }

      case "processingCode": {
        let tempFees = [...installPartnerFees.installPartnerFees];
        if (value && value.length) {
          tempFees[index][field] = { value, error: false, errorText: "Select Code", success: true };
        } else {
          tempFees[index][field] = { value: "", error: true, errorText: "Select Code", success: false };
        }
        installPartnerFees.setInstallPartnerFees(tempFees);
        break;
      }
      case "quantity": {
        let tempFees = [...installPartnerFees.installPartnerFees];
        if (value && value > 0 && value <= 100) {
          tempFees[index][field] = { value, error: false, errorText: "Enter Quantity", success: true };
          if (feeNotification.current === true) {
            feeNotification.current = false;
            setNotification(null);
          }
        } else if (value && value > 100) {
          tempFees[index][field] = { value, error: true, errorText: "", success: true };
          notifyError("You entered a quantity more than 100. Are you sure you want to proceed?", "error");
          feeNotification.current = true;
        } else {
          tempFees[index][field] = { value: 0, error: true, errorText: "Enter Valid Quantity", success: false };
        }
        installPartnerFees.setInstallPartnerFees(tempFees);
        break;
      }
    }
  };

  const addMoreFees = (option) => {
    let tempFees = installPartnerFees.installPartnerFees?.length > 0 ? [...installPartnerFees.installPartnerFees] : [];
    tempFees.push({
      itemDescription: { value: "", error: false, errorText: "Select Description", success: true },
      mmid: { value: "", error: false, errorText: "Enter MMID", success: false },
      processingCode: { value: "", error: false, errorText: "Select Code", success: false },
      quantity: { value: "", error: false, errorText: "Enter Quantity", success: false },
      unit: {
        value: "",
        error: false,
        errorText: "Enter Unit",
        success: true,
      },
    });

    let desc = option.text;
    let mmid = option.value;
    tempFees[installPartnerFees.installPartnerFees?.length]["itemDescription"] = {
      value: desc,
      error: false,
      errorText: "Select Description",
      success: true,
    };
    tempFees[installPartnerFees.installPartnerFees?.length]["mmid"] = { value: mmid, error: false, errorText: "Enter MMID", success: true };
    tempFees[installPartnerFees.installPartnerFees?.length]["unit"] = {
      value: option.baseMeasurementUnit,
      error: false,
      errorText: "Enter Unit of Measure",
      success: true,
    };
    installPartnerFees.setInstallPartnerFees(tempFees);
    //for accessibility
    addFeesref.current.focus();
  };

  const notifyError = (content, type) => {
    setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
  };

  const previousStepModalFocus = () => {
    previousStep();
    stepModalFocus();
  };

  return (
    <>
      <Spacer top="4vw" />
      <FlexRow style={{ justifyContent: "space-between", padding: "5px", fontFamily: "" }}>
        <div
          ref={InstallPartnerFeesTitle}
          tabIndex={0}
          style={{ fontSize: "2rem", fontWeight: 700, color: "#000", marginTop: "0.5em", display: "inline-block" }}
        >
          Install Partner Fees
        </div>
        <IconWrapper onClick={() => exportExcel()} ref={addFeesref}>
          {/* <CustomTooltip title="Download Excel" size="large"></CustomTooltip> */}
          <img src={xlsIcon}></img>
        </IconWrapper>
      </FlexRow>
      {notification}
      {showAddInstallerFees && (
        <Spacer top="1vw" bottom="1.2rem">
          <div style={{ padding: "5px", paddingBottom: "0px", width: "50%", fontSize: "13px" }}>
            <Select options={descriptionList} onChange={handleSelectChange} isSearchable={true} css={CustomSelectCSS} styles={customStyle} />
          </div>
        </Spacer>
      )}
      {!showAddInstallerFees &&
      appointmentDate > new Date(jobDetails.completionDate).getTime() &&
      appointmentDate - new Date(jobDetails.completionDate).getTime() < 48 * 60 * 60 * 1000 ? (
        <Spacer top="1vw" bottom="1.2rem">
          <div style={{ padding: "5px", paddingBottom: "0px", width: "50%", fontSize: "13px" }}>
            <Select options={descriptionList} onChange={handleSelectChange} isSearchable={true} css={CustomSelectCSS} styles={customStyle} />
          </div>
        </Spacer>
      ) : (
        <div></div>
      )}
      {/* <div css={{ height: installPartnerFeesData.length + installPartnerFees.installPartnerFees.length > 3 ? "38vh" : "", overflowY: "auto" }}> */}
      <FlexRow style={{ padding: "5px", marginBottom: "0px", paddingBottom: "0px" }}>
        <Table tabIndex={0} role="table" topLine="none" css={CustomTableHeader}>
          <TableHead role="row">
            <TableHeader role="columnheader" width="55%">
              <SmallText bold>Item Description</SmallText>
            </TableHeader>
            <TableHeader role="columnheader" width="30%">
              <SmallText bold>MMID</SmallText>
            </TableHeader>
            <TableHeader role="columnheader" width="40%">
              <SmallText bold>Processing Code</SmallText>
            </TableHeader>
            <TableHeader role="columnheader" width="25%">
              <SmallText bold>Quantity</SmallText>{" "}
            </TableHeader>
            <TableHeader role="columnheader" width="25%">
              <SmallText bold>Unit of Measure</SmallText>
            </TableHeader>
          </TableHead>
        </Table>
        {/* <div css={{ height: "15vh", overflowY: "auto" }}> */}
        <Loader active={isLoading} fullscreen={false} />
        <div css={{ height: "24vh", overflowY: "scroll" }}>
          {installPartnerFeesData.length > 0 ? (
            <Table tabIndex={0} role="table" topLine="none" striped={false} css={CustomTableHeader}>
              <TableBody role="rowgroup">
                {installPartnerFeesData.map((job) => {
                  return (
                    <>
                      <TableRow role="row" key={job.materialId}>
                        <Cell role="cell" width="55%">
                          <SmallText>{job.materialName}</SmallText>
                        </Cell>
                        <Cell role="cell" width="30%">
                          <SmallText>{job.materialId}</SmallText>
                        </Cell>
                        <Cell role="cell" width="40%">
                          <SmallText>{job.processingCode}</SmallText>
                        </Cell>
                        <Cell role="cell" width="25%">
                          <SmallText>{job.quantity}</SmallText>
                        </Cell>
                        <Cell role="cell" width="25%">
                          <SmallText>{job.unitOfMeasure}</SmallText>
                        </Cell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            installPartnerFees.installPartnerFees.length == 0 && (
              <Table tabIndex={0} role="table" topLine="none" striped={false} css={CustomTableHeader}>
                <TableBody role="rowgroup">
                  <TableRow role="row">
                    <Cell role="cell">
                      <SmallText>No Data</SmallText>
                    </Cell>
                  </TableRow>
                </TableBody>
              </Table>
            )
          )}
          {installPartnerFees.installPartnerFees &&
            installPartnerFees.installPartnerFees.length > 0 &&
            installPartnerFees.installPartnerFees.map((el, index) => {
              return (
                <FlexRow key={index} style={{ justifyContent: "space-between", padding: "5px", paddingBottom: "0px", paddingLeft: "0px" }}>
                  <CustomInputDynamicWidth
                    width="29%"
                    type="text"
                    // label={installPartnerFeesData.length > 0 ? "" : "Item Description"}
                    // aria-label={installPartnerFeesData.length > 0 ? "" : "Item Description"}
                    // name="description"
                    disabled
                    value={el.itemDescription.value}
                    error={el.itemDescription.error}
                    errorText={el.itemDescription.errorText}
                    success={el.itemDescription.success}
                    paddingRight="5px"
                  />
                  <CustomInputDynamicWidth
                    width="16%"
                    type="text"
                    // label="MMID"
                    // label={installPartnerFeesData.length > 0 ? "" : "MMID"}
                    // aria-label={installPartnerFeesData.length > 0 ? "" : "MMID"}
                    name="mmid"
                    disabled
                    value={el.mmid.value}
                    error={el.mmid.error}
                    errorText={el.mmid.errorText}
                    success={el.mmid.success}
                    paddingRight="5px"
                  />
                  <CustomDropdown
                    width="23%"
                    type="text"
                    // label="Processing Code"
                    // label={installPartnerFeesData.length > 0 ? "" : "Processing Code"}
                    // aria-label={installPartnerFeesData.length > 0 ? "" : "Processing Code"}
                    value={el.processingCode.value || "Please select"}
                    error={el.processingCode.error}
                    errorText={el.processingCode.errorText}
                    success={el.processingCode.success}
                    onChange={(e) => handleFields("processingCode", e.target.value, index)}
                    // paddingRight="5px"
                  >
                    <>
                      <option disabled>Please select</option>
                      {processingCodes.map((type) => (
                        <option key={type.code}>
                          {type.code} - {type.description}
                        </option>
                      ))}
                    </>
                  </CustomDropdown>
                  <CustomInputDynamicWidth
                    width="11%"
                    type="number"
                    // label="Quantity"
                    // label={installPartnerFeesData.length > 0 ? "" : "Quantity"}
                    // aria-label={installPartnerFeesData.length > 0 ? "" : "Quantity"}
                    value={el.quantity.value}
                    error={el.quantity.error}
                    errorText={el.quantity.errorText}
                    success={el.quantity.success}
                    onChange={(e) => handleFields("quantity", e.target.value, index)}
                    paddingRight="5px"
                  />
                  <CustomInputDynamicWidth
                    width="15%"
                    type="text"
                    // label="Unit of Measure"
                    // label={installPartnerFeesData.length > 0 ? "" : "Unit of Measure"}
                    // aria-label={installPartnerFeesData.length > 0 ? "" : "Unit of Measure"}
                    value={el.unit.value}
                    error={el.unit.error}
                    errorText={el.unit.errorText}
                    success={el.unit.success}
                    disabled
                    paddingRight="5px"
                  />
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => deleteRow(index)}>
                    <Icon
                      className="trash-icon"
                      name="trash"
                      size="medium"
                      color="#000000"
                      style={{ cursor: "pointer", outline: "none", marginTop: "7px" }}
                    />
                  </div>
                </FlexRow>
              );
            })}
        </div>
        {/* </div> */}
      </FlexRow>
      {/* </div> */}
      {/* {installPartnerFeesData.length + installPartnerFees.installPartnerFees.length > 4 && <Spacer top="1vw" />} */}

      <FlexRow style={{ justifyContent: "flex-start", marginTop: "0.5rem" }}>
        {formStep > 0 && (
          <SLButton
            aria-label="Previous"
            role="button"
            size="large"
            style={{ width: "10rem", marginTop: "5px", marginBottom: "0px" }}
            onClick={previousStepModalFocus}
          >
            Previous
          </SLButton>
        )}
        <SLButton
          aria-label="Next"
          role="button"
          size="large"
          style={{ width: "10rem", marginTop: "5px", marginBottom: "0px" }}
          disabled={stepsEnable.second && installPartnerFees.installPartnerFees.length > 0}
          aria-disabled={stepsEnable.second && installPartnerFees.installPartnerFees.length > 0}
          onClick={nextStep}
        >
          Next
        </SLButton>
        <SLButton aria-label="Cancel" role="button" size="large" secondary style={{ width: "10rem", marginBottom: "0px" }} onClick={closePopup}>
          Cancel
        </SLButton>
      </FlexRow>
    </>
  );
};
export default InstallPartnerFees;
