import styled from "@emotion/styled";
import { Loader } from "@vds/loaders";
import { Title } from "@vds/typography";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { FlexRow, Spacer } from "../../components/core/Utility";
import { PrivilegeContext } from "../../context/privilegeContext";
import { fetchGridComponentState, saveGridComponentState } from "../../utility/persistGridComponentState";
import { SHIPMENT_EVENTS_COLUMNS } from "./shipmentEventsColumns";
import { ProgressFlow } from "../../components/features/ProgressFlow";
import { Button } from "@vds/buttons";
import { getShipmentEventsByOpp } from "../../services/orders/getShipmentEventsByOpp";
import { retryShipmentTask } from "../../services/orders/retryShipmentTask";

const LocationStyle = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`;

const CustomImage = styled.div`
  display: flex;
`;

const GridStyle = styled.div`
  .datatable-style .mark-red {
    background-color: rgba(247, 65, 65, 0.15) !important;
  }
`;
const rowClass = (data) => {
  return {
    "mark-red": data.stage?.endsWith("Failed"),
  };
};

const RowTemplate = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const retryShipmentEvent = async (payload) => {
    setLoading(true);
    await retryShipmentTask(payload).then((response) => {});
    setLoading(false);
  };
  return (
    <>
      <Loader active={loading} />
      <FlexRow
        margin="10px"
        padding="50px 50px 50px"
        flexBasis="1171px"
        alignItems="flex-start"
        css={{ background: "#fbfbfb", border: "1px solid #d8dada", borderRadius: "5px" }}
      >
        <ProgressFlow data={data.events} />
        <Spacer height="103px" />
        {data.events.filter((element) => element.status != "SUCCESS").length > 0 ? (
          <Button secondary={true} size="small" onClick={() => retryShipmentEvent({ esn: data.esn, retryFlag: true })}>
            Retry
          </Button>
        ) : (
          <></>
        )}
      </FlexRow>
    </>
  );
};

const rowExpansionTemplate = (data) => {
  return <RowTemplate data={data} />;
};

const Shipment = ({ salesforceOpportunityId }) => {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState();
  const [shipmentEventsData, setShipmentEventsData] = useState([]);

  const tableRef = useRef();
  const { userInfo } = useContext(PrivilegeContext);
  const onCustomSaveState = (state) => {
    saveGridComponentState(state, "reports_info");
  };

  const onCustomRestoreState = async (state) => {
    return await fetchGridComponentState("reports_info");
  };

  useEffect(() => {
    fetchShipmentData(salesforceOpportunityId);
  }, [salesforceOpportunityId]);

  const getData = (arr) => {
    let set = new Set();
    arr.forEach((e) => {
      set.add(e.esn);
    });

    let res = [];
    set.forEach((e) => {
      let status = arr.filter((f) => f.esn == e);
      let events = getShipmentEvents(status);

      let o = { events: events };
      status.forEach((e) => {
        o = { ...o, ...e, [e.status]: e.createdAt };
      });
      res.push(o);
    });
    return res;
  };

  const getEventsByFlow = (statusList) => {
    try {
      if (statusList.filter((e) => e.status == "SHIPMENT_RECEIVED").length > 0) {
        return [
          { title: "SHIPMENT_RECEIVED" },
          { title: "GetUniversalAccountId" },
          { title: "RevealAccountValidate" },
          { title: "CreateDeviceInReveal" },
        ];
      } else if (statusList.filter((e) => e.status == "DECOMM_SHIPMENT_RECEIVED").length > 0) {
        return [{ title: "DECOMM_SHIPMENT_RECEIVED" }, { title: "DecommissionDeviceInReveal" }];
      } else if (statusList.filter((e) => e.status == "RETURN_SHIPMENT_RECEIVED").length > 0) {
        return [{ title: "RETURN_SHIPMENT_RECEIVED" }, { title: "DecommissionDeviceInReveal" }, { title: "DecomissionDeviceInSAP" }];
      }
    } catch (err) {
      return [];
    }
    return [];
  };

  const fetchShipmentData = async (salesforceOpportunityId) => {
    setLoading(true);
    await getShipmentEventsByOpp(salesforceOpportunityId).then((response) => {
      if (response?.response?.status === 404) {
        setShipmentEventsData([]);
      } else if (response.rows) {
        setShipmentEventsData(response.rows);
      }
    });
    setLoading(false);
  };

  const getShipmentEvents = (statusList) => {
    let events = [];
    try {
      const FAILED = "FAILED";
      const SUCCESS = "SUCCESS";
      const INPROGRESS = "INPROGRESS";
      const map = {
        SHIPMENT_RECEIVED: { name: "SHIPMENT_RECEIVED", value: SUCCESS },
        GetUniversalAccountIdSuccess: { name: "GetUniversalAccountId", value: SUCCESS },
        GetUniversalAccountIdFailed: { name: "GetUniversalAccountId", value: SUCCESS },
        RevealAccountValidateSuccess: { name: "RevealAccountValidate", value: SUCCESS },
        RevealAccountValidateFailed: { name: "RevealAccountValidate", value: FAILED },
        CreateVehicleRequestSent: { name: "CreateDeviceInReveal", value: INPROGRESS },
        CreateVehicleFailed: { name: "CreateDeviceInReveal", value: FAILED },
        CreateVehicleSuccess: { name: "CreateDeviceInReveal", value: SUCCESS },
        DECOMM_SHIPMENT_RECEIVED: { name: "DECOMM_SHIPMENT_RECEIVED", value: SUCCESS },
        RETURN_SHIPMENT_RECEIVED: { name: "RETURN_SHIPMENT_RECEIVED", value: SUCCESS },
        decomSAPSuccess: { name: "DecomissionDeviceInSAP", value: SUCCESS },
        decomSAPFailed: { name: "DecomissionDeviceInSAP", value: FAILED },
        DECOMM_REQUEST_SENT: { name: "DecommissionDeviceInReveal", value: INPROGRESS },
        DecommissionVehicleSuccess: { name: "DecommissionDeviceInReveal", value: SUCCESS },
        DecommissionVehicleFailed: { name: "DecommissionDeviceInReveal", value: FAILED },
      };

      events = getEventsByFlow(statusList);
      statusList.map((statusData) => {
        let eventsDetails = map[statusData.status];
        if (eventsDetails != null) {
          let temp = events.filter((e) => e.title == eventsDetails.name)[0];
          if (temp == null) {
            events.push({ title: eventsDetails.name, status: eventsDetails.value });
          } else {
            temp.status = eventsDetails.value;
          }
        }
      });
    } catch (err) {
      return events;
    }
    return events;
  };

  return (
    <div>
      <LocationStyle>
        {notification}
        <Loader active={loading} />

        <FlexRow justifyContent="space-between" padding="10px 0">
          <Title primitive="p" size="medium">
            Shipment Data
          </Title>
          <FlexRow
            css={{ justifyContent: "center", alignItems: "center", backgroundColor: "gray", width: "35px", height: "35px", borderRadius: "5px" }}
            onClick={() => fetchShipmentData(salesforceOpportunityId)}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.7875 2.20312C11.4281 0.84375 9.5625 0 7.49062 0C3.34687 0 0 3.35625 0 7.5C0 11.6438 3.34687 15 7.49062 15C10.9875 15 13.9031 12.6094 14.7375 9.375H12.7875C12.0187 11.5594 9.9375 13.125 7.49062 13.125C4.3875 13.125 1.86562 10.6031 1.86562 7.5C1.86562 4.39688 4.3875 1.875 7.49062 1.875C9.04687 1.875 10.4344 2.52188 11.4469 3.54375L8.42812 6.5625H14.9906V0L12.7875 2.20312Z"
                fill="white"
              />
            </svg>
          </FlexRow>
        </FlexRow>

        <br></br>
        <GridStyle>
          <div>
            <GridComponent
              ref={tableRef}
              isSelectionEnabled={false}
              stateStorage="custom"
              customSaveState={onCustomSaveState}
              customRestoreState={onCustomRestoreState}
              rowClassName={rowClass}
              isExportEnabled={true}
              noDataMessage="Shipment Events is Empty"
              rows={getData(shipmentEventsData)}
              columns={SHIPMENT_EVENTS_COLUMNS}
              title="Shipment Data"
              enableColumnSelection
              reorderableColumns
              showResetButton
              rowExpansionTemplate={rowExpansionTemplate}
              rowExpansionPosition="beginning"
              scrollHeight="900px"
            />
          </div>
        </GridStyle>
      </LocationStyle>
    </div>
  );
};
export default Shipment;
