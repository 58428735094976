import { generate } from "shortid";
import Calamp from "../assets/Files/calamp.csv";
import Refurbished from "../assets/Files/refurbished.csv";
import Novatel from "../assets/Files/Novatel.csv";
import Pointer from "../assets/Files/Pointer.csv";
import GenX from "../assets/Files/GenX.csv";
import WiFiCamera from "../assets/Files/WiFiCamera.csv";
import CatM1 from "../assets/Files/CatM1.csv";
import SmartWitness from "../assets/Files/SmartWitness.csv";
import ExtendedViewCamera from "../assets/Files/ExtendedViewCamera.csv";
import VDD from "../assets/Files/VDD.csv";
import Xirgo from "../assets/Files/Xirgo.txt";
export const FORCE_CLOSE_TAB = "FORCE_CLOSE_TAB";
export const OPEN_TAB_EVENT = "OPEN_TAB_EVENT";
export const ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE = "ON_OPEN_CLOSE_NEW_TAB_HOMEPAGE";
export const ADD_PAGE = "ADD_SUBPAGE";
export const CHANGE_PAGE = "CHANGE_SUBPAGE";
export const APPEND_TO_TAB_ID = "_TabMainId_" + generate();
export const RESET_TAB = "RESET_PAGE";
export const UPDATE_TAB = "UPDATE_TAB";
export const UPDATE_QUICK_LINKS = "UPDATE_QUICK_LINKS";

export const ACTIVATE_BATCH_UPLOAD_HISTORY = {
  0: "batchNumber",
  1: "Brand",
  2: "Units",
  3: "batchFilename",
  4: "Upload Date",
  5: "Uploaded By User",
};
export const SELECT_REVEAL_VEHICLES_HEADER = {
  0: "Account",
  1: "Box Type",
  2: "Reveal ID",
  3: "Label",
  4: "Make",
  5: "Model",
  6: "Reg #",
  7: "VIN",
  8: "Year",
};

export const UPLOAD_INACTIVE_UNITS = {
  0: "Batch #",
  1: "Brand",
  2: "Units",
  3: "Filename",
  4: "Upload Date",
  5: "Uploaded By User",
};

export const SAMPLE_REFURBISHED_DOWNLOAD_FILES = [
  {
    key: "Refurbished",
    fileName: Refurbished,
    type: "csv",
    suported: true,
  },
];

export const SAMPLE_DOWNLOAD_FILES = [
  {
    key: "CalAmp",
    fileName: Calamp,
    type: "csv",
    suported: true,
  },
  {
    key: "Novatel",
    fileName: Novatel,
    type: "csv",
    suported: false,
  },
  {
    key: "Pointer",
    fileName: Pointer,
    type: "csv",
    suported: false,
  },
  {
    key: "Xirgo",
    fileName: Xirgo,
    type: "txt",
    suported: true,
  },
  {
    key: "GenX",
    fileName: GenX,
    type: "csv",
    suported: false,
  },
  {
    key: "WiFi Camera",
    fileName: WiFiCamera,
    type: "csv",
    suported: true,
  },
  {
    key: "CatM1",
    fileName: CatM1,
    type: "csv",
    suported: false,
  },
  {
    key: "SmartWitness",
    fileName: SmartWitness,
    type: "csv",
    suported: true,
  },
  {
    key: "ExtendedViewCamera",
    fileName: ExtendedViewCamera,
    type: "csv",
    suported: process.env.REACT_APP_EVC_UPLOAD_ENABLED === "true",
  },
  {
    key: "VDD",
    fileName: VDD,
    type: "csv",
    suported: true,
  },
];
export const COUNTRYS = {
  USA: "United States",
  CAN: "Canada",
  MEX: "Mexico",
  AUS: "Australia",
  CHL: "Chile",
  GBR: "United Kingdom",
  IRL: "Ireland",
  PRT: "Portugal",
  DEU: "Germany",
  POL: "Poland",
  FRA: "France",
  NZL: "New Zealand",
  NLD: "Netherlands",
  ITL: "Italy",
};
export const SELECT_WORK_TICKET_HEADER = {
  0: "Work Ticket#",
  1: "Vendor",
  2: "Installation Address",
  3: "Ticket Type",
  4: "CRMReferenceID",
  5: "Status",
};
export const SELECT_LINE_ITEM_HEADER = {
  0: "Work Model#",
  1: "Line Items",
  2: "Vendor",
  3: "Installation Address",
  4: "Model Type",
  5: "Peripherals",
  6: "CRMReferenceID",
  7: "Status",
};
export const CHECKIN_SEARCH_DROPDOWN_VALUES = {
  esn: "ESN",
  imei: "IMEI",
  meid: "MEID",
  phone: "Phone",
  workTicketId: "Work Ticket",
  iccid: "ICCID",
};

export const DEVICE_REPORT_SEARCH_DROPDOWN_VALUES = {
  empty: "",
  esn: "ESN",
  techRefId: "Tech Ref Id",
  vzcNumber: "VZC Number",
  accountNumber: "Account Number",
  meid: "MEID",
  iccid: "ICCID",
  drid: "DRID",
  vtuEsn: "VTU ESN (camera)",
  phoneNumber: "Phone",
  workOrderNumber: "Work Order",
  vehicleTrackingId: "VID",
  vehicleRegNumber: "Vehicle Plate",
  vin: "VIN",
  imei: "IMEI",
  revealID: "Countrol Unit Id",
};

export const VIEW_BATCH = {
  0: "Select",
  1: "esn",
  2: "gsm",
  3: "iccid",
  4: "meid",
  5: "status",
  6: "unitType",
  7: "boxType",
};

export const CARRIER = {
  0: "",
  1: "AT&T",
  2: "Telstra",
  3: "Verizon",
  4: "Vodafone",
};

export const RATEPLAN = {
  0: "",
  1: "Telogis - 1MB Intl Plan",
  2: "Telogis - 25MB Intl Plan",
  3: "Telogis - 100MB Intl Plan",
  4: "Fleetmatics 1MB Pooled 3G Plan",
  5: "Fleetmatics NA 3MB Pooled Plan",
};

export const VERIZON_RATEPLAN = {
  0: "",
  1: "Verizon LTE",
  2: "Verizon 3G",
  3: "Vechile Data Device",
  4: "Equipment Asset Tracking Rate Plan",
  5: "FleetCamera4GPlan",
};

export const VIEW_BATCH_PROFILE = {
  0: "",
  1: "21",
  2: "20",
};
export const SELECT_REVEAL_CAMERAS_HEADER = {
  0: "Account ID",
  1: "Location",
  2: "Serial Number",
};

export const COLUMN_WIDTHS = {
  xsmall: "110px",
  small: "150px",
  medium: "220px",
  large: "320px",
  xmedium: "250px",
  lsmall: "180px",
};

export const EDIT_FIELD_TYPES = {
  dropDown: "DropDown",
  inputText: "inputText",
  inputNumber: "inputNumber",
};
export const SCHEDULER_UPDATE_DROPDOWN_VALUES = {
  "": "",
  M1: "Milestone 1",
  M2: "Milestone 2",
  OC: "Order Create",
  CA: "Carrier_Activation",
  OR: "Order Rewrite",
  RAC: "Reveal Account Creation",
  SHIPMENT: "Shipment",
  BS: "Buyout Simulation",
  OCA: "Order Cancellation",
  BC: "Blended Cancellation",
};
export const SCHEDULER_VALUES_DROPDOWN_VALUES = {
  "": "",
  True: "true",
  False: "false",
};
export const RBAC_ACTION_VALUES = {
  "": "",
  assignroletogroup: "ASSING_ROLE_TO_GROUP",
  assigngrouptoprivilege: "ASSING_GROUP_TO_PRIVILEGE",
  UserManagement: "USER_MANAGEMENT",
};

//added it as part of VZC42148-106
export const CUSTOM_FILTER_TYPE = {
  listBox: "List Box",
  dropDown: "Drop Down",
};

// audit log
export const AUDIT_LOG_DROPDOWN_VALUES = {
  vtu: "VTU (ESN)",
  camera: "Camera (ESN)",
  user: "User (Example: thomas.anderson@verizon.com)",
};

export const AUDIT_LOGS_COL = [
  {
    key: "Time",
    value: "time",
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Record",
    value: "record",
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    width: COLUMN_WIDTHS.lsmall,
  },
  {
    key: "User",
    value: "user",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    isUser: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Action",
    value: "action",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    width: COLUMN_WIDTHS.xsmall,
  },
  {
    key: "Method",
    value: "method",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Changes",
    value: "changes",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    isWrap: true,
    width: "450px",
    maxWidth: "450px",
  },
  {
    key: "SL2.0/Legacy",
    value: "where",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    width: COLUMN_WIDTHS.xsmall,
  },
  {
    key: "TraceID",
    value: "traceId",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    isList: false,
    width: COLUMN_WIDTHS.small,
  },
];

export const EVENT_TYPE_SEARCH_DROPDOWN_VALUES = {
  empty: "",
  OptyCancelEvent: "Opty Cancel",
  DecommissionDeviceInRevealEvent: "Decomm Device in reveal",
  DecommissionAccountInRevealEvent: "Decomm Account in reveal",
  FinancialStatusUpdateEvent: "Update Financial Status",
};

export const EVENT_STATE_SEARCH_DROPDOWN_VALUES = {
  empty: "",
  new: "New",
  processing: "Processing",
  succeeded: "Succeeded",
  failed: "Failed",
  cancelled: "Cancelled",
  retried: "Retried",
  unknown: "Unknown",
};
